import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Table, Modal, message, Button, Tag, Radio, Card, Row, Col,
  Pagination, Spin, Divider, Popconfirm, Empty, Dropdown, Space, Menu, Checkbox
} from '@om-tlh/bee';
import { PlusOutlined, EllipsisOutlined, DownOutlined } from '@ant-design/icons'
import SimpleSearch from '../SimpleSearch'
import LocalStorageUtil from '@/utils/localStorageUtil';
import SearchType from '@/utils/searchType';
import DictUtil from '@/utils/dictUtil'
import Detail from './components/detail'
import DragTitle from '@/components/DragTitle'
import utils from '@/utils/util'
import styles from './index.module.css'
import ModalForm from './components/ModalForm'

const { Meta } = Card;

class TablePage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pageSize: LocalStorageUtil.getPageSize(),
      pageNumber: props.pageNumber ? props.pageNumber : 1,
      filter: props.filter ? props.filter : '',
      sorter: props.sorter ? props.sorter : {},
      loading: true,
      initTable: false,
      isReload: props.isReload || false,
      count: props.count,
      catalogId: props.catalogId,
      pagination: props.hidePagination ? false : {},
      pageSizeChanged: false,
      columns: props.columns,
      dataSource: [],
      total: 0,
      hidePagination: props.hidePagination,
      visible: false,
      currentTab: LocalStorageUtil.getStorage('tabType') || 'card',
      formVisible: false,
      type: 'create',
      record: {},
      scope: props.scope,
      expandedRowKeys: [],

      _rebuildColumns: this._rebuildColumns,
      loadData: this.loadData
    }
    this.isUnmount = false
    this.form = React.createRef()
    const { pageNumber, pageSize, filter, sorter } = this.state
    props.getLoadData && props.getLoadData(() =>
      this.loadData(this.state.pageNumber, this.state.pageSize, this.state.filter, this.state.sorter))
  }

  componentDidMount() {
    const { pageNumber, pageSize, filter, sorter } = this.state
    this.loadData(pageNumber, pageSize, filter, sorter, this.props.addFilter, this.props.scope)
    this.setState({
      columns: this._rebuildColumns(this.props.fields),
      scope: this.props.scope
    })
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   // if (!nextProps.visible) return
  //   this.setState({ columns: this._rebuildColumns(nextProps.fields), scope: nextProps.scope })
  //   const { pageNumber, pageSize, filter, sorter } = this.state
  //   if (nextProps.isReload !== this.props.isReload && nextProps.isReload)
  //     this.loadData(pageNumber, pageSize, filter, sorter, nextProps.addFilter, nextProps.scope)
  //   if (nextProps.count !== this.props.count) {
  //     this.loadData(1, pageSize, filter, sorter, nextProps.addFilter, nextProps.scope)
  //   }
  //   if (nextProps.catalogId !== this.props.catalogId) {
  //     this.loadData(1, pageSize, filter, sorter, nextProps.addFilter, nextProps.scope, nextProps.catalogId)
  //   }
  // }

  static getDerivedStateFromProps(nextProps, { isReload, count, catalogId, ...rest }) {
    if (nextProps.isReload !== isReload) {
      const { pageNumber, pageSize, filter, sorter } = rest
      setTimeout(() => {
        rest.loadData(pageNumber, pageSize, filter, sorter, nextProps.addFilter, nextProps.scope)
      }, 0)
      return {
        columns: rest._rebuildColumns(nextProps.fields),
        scope: nextProps.scope,
        isReload: nextProps.isReload,
        count: nextProps.count,
        catalogId: nextProps.catalogId
      }
    }
    if (nextProps.count !== count || nextProps.catalogId !== catalogId) {
      const { pageNumber, pageSize, filter, sorter } = rest
      setTimeout(() => {
        rest.loadData(1, pageSize, filter, sorter, nextProps.addFilter, nextProps.scope, nextProps.catalogId)
      }, 0)
      return {
        columns: rest._rebuildColumns(nextProps.fields),
        scope: nextProps.scope,
        isReload: nextProps.isReload,
        count: nextProps.count,
        catalogId: nextProps.catalogId
      }
    }
    return {
      columns: rest._rebuildColumns(nextProps.fields),
      scope: nextProps.scope,
      isReload: nextProps.isReload,
      count: nextProps.count,
      catalogId: nextProps.catalogId
    }
  }

  loadData = (pageNumber = 1, pageSize, filter, sorter, addFilter, scope, catalogId) => {
    const { intl, loadData, dataSource = [], resultType, catalogId: originCatalogId = '' } = this.props;
    if (dataSource && dataSource.length) {
      this.setState({ loading: true })
      setTimeout(() => {
        this.setState({ dataSource, loading: false })
      }, 500)
      return;
    }
    const { formatMessage } = intl;
    this.setState({ loading: true })
    let params = {
      page: pageNumber,
      page_size: pageSize,
      ...filter,
      ...sorter,
      ...(addFilter || this.props.addFilter)
    };

    if (catalogId) params.label_id = '';

    // loadData && loadData({
    //   page: pageNumber,
    //   page_size: pageSize,
    //   ...filter,
    //   ...sorter,
    //   ...(addFilter || this.props.addFilter)
    // })
    (catalogId ?? originCatalogId ? loadData(catalogId ?? originCatalogId, params) : loadData(params)).then(data => {
      if (dataSource && dataSource.length) {
        this.setState({
          dataSource: dataSource.map(i => {
            return {
              ...i,
              ...data.find(j => j.channel === i.channel)
            }
          })
        })
      } else {
        const items = data.items ? data.items : ((data.pager?.items ?? data) || [])
        this.setState({ dataSource: items, })
        if (this.props.expandable) {
          this.setState({
            expandedRowKeys: items.map(i => i[this.props.rowKey])
          })
        }
      }
      this.props.onSelect && this.props.onSelect([])
      !this.isUnmount && this.setState({
        loading: false,
        // dataSource: data.items ? data.items : data,
        total: data.total || data.pager?.total,
        pageNumber, pageSize, filter, sorter,
        selectedRowKeys: []
      }, () => {
        this.setState({ pagination: this._createPagination(this.state) })
      })
      if (data.items && data.items.length === 0 && (data.total || data.pager?.total) && pageNumber - 1 > 0) {
        this.loadData(pageNumber - 1, pageSize, filter, sorter)
      }
    }).catch(error => {
      this.setState({ loading: false })
      message.error((error.response && error.response.data.message) || formatMessage({ id: 'unknown_error' }))
    })
  }

  _handleShowDetail = (e, record) => {
    e.preventDefault();
    this.setState({ record, visible: true })
  }

  _renderDetailModal = (record, fields) => {
    const { intl, name } = this.props;
    const { formatMessage } = intl;
    return (
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        width={800}
        title={<DragTitle title={name + formatMessage({ id: 'title_detail' })} className='detail_modal' />}
        open={this.state.visible}
        onOk={() => this.handleOk()}
        onCancel={() => this.setState({ visible: false })}
        footer={false}
        wrapClassName='detail_modal'
      >
        <Detail key={`${this.state.visible}_${Date.now()}`} {...this.props} record={record} fields={fields} />
      </Modal>
    )
  }

  _renderFormModal = (record) => {
    const { intl, name, createText } = this.props;
    const { formatMessage } = intl;
    const { type } = this.state

    return (
      <ModalForm
        {...this.props}
        open={this.state.formVisible}
        onCreate={this.handleOk}
        onCancel={this.handleCancel}
        record={record}
        type={type}
        title={<DragTitle title={type === 'create' ? `${createText || formatMessage({ id: 'title_create' })}${name}` : `${formatMessage({ id: 'title_edit' })}${name}`} className='form_modal' />}
      />
    )
  }

  handleOk = (values) => {
    const { intl, moduleName } = this.props
    const { formatMessage } = intl
    const { type } = this.state
    const { pageNumber, pageSize, filter, sorter, record } = this.state
    const { service, name } = this.props
    const { createData, updateData } = service;
    (type === 'create' ? createData(values) : updateData(record[moduleName ? `${moduleName}_id` : 'id'], values)).then(data => {
      this.loadData(pageNumber, pageSize, filter, sorter)
      message.success(`${name}${type === 'create' ? formatMessage({ id: 'title_create' }) : formatMessage({ id: 'title_edit' })}${formatMessage({ id: 'success' })}`)
      this.handleCancel()
    })
  }

  handleSubmit = (record) => {
    const { intl } = this.props
    const { formatMessage } = intl
    const { type } = this.state
    const { pageNumber, pageSize, filter, sorter } = this.state
    const { createData, updateData, name } = this.props
    this.form.current && this.form.current.validateFields((err, values) => {
      if (!err) {
        (type === 'create' ? createData(values) : updateData(record.id, values)).then(data => {
          this.loadData(pageNumber, pageSize, filter, sorter)
          message.success(`${name}${type === 'create' ? formatMessage({ id: 'title_create' }) : formatMessage({ id: 'title_edit' })}${formatMessage({ id: 'success' })}`)
          this.handleCancel()
        })
      }
    })
  }

  handleCancel = () => {
    this.setState({ formVisible: false })
  }

  _onTableChange(params) {
    this.setState({ pageNumber: params.pageNumber, pageSize: params.pageSize })
    this.loadData(params.pageNumber, params.pageSize, params.filter, params.sorter)
  }

  _createPagination(props) {
    const { intl, size, hideSizeChanger, hidePagination } = this.props
    const { formatMessage } = intl
    return !hidePagination ? (props.total
      ? size === 'small'
        ? {
          pageSize: props.pageSize,
          total: props.total,
          current: props.pageNumber || this.state.pagination.current || 1,
          showSizeChanger: false
        }
        : {
          showQuickJumper: true,
          showSizeChanger: hideSizeChanger ? false : true,
          pageSize: props.pageSize,
          total: props.total,
          current: props.pageNumber || this.state.pagination.current || 1,
          showTotal: (total, range) => formatMessage({ id: 'page_message' }, { range_0: range[0], range_1: range[1], total: total }),
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: this._onShowSizeChange
        }
      : false) : false
  }

  _onShowSizeChange = (current, pageSize) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        current: 1,
        pageSize: pageSize
      },
      pageSizeChanged: true
    })

    LocalStorageUtil.setPageSize(pageSize);

    this.setState({
      pagination: this.state.pagination,
      pageSizeChanged: true,
    });
  }

  _onChange = (pagination, filter, sorter) => {
    let pager = this.state.pagination;
    if (!pager) pager = {}
    if (!this.state.pageSizeChanged) {
      pager.current = pagination.current || 1;
    }

    let _sorter = sorter && sorter.field && sorter['order'] ? {
      sort_by: sorter.field,
      order: sorter['order'].substring(0, sorter['order'].length - 3)
    } : {};

    this._onTableChange({
      pageNumber: pager.current,
      pageSize: pagination.pageSize,
      sorter: _sorter,
      filter: Object.assign({}, this.state.filter, filter)
    });

    this.setState({
      pagination: pager,
      sorter: _sorter,
      filter: Object.assign({}, this.state.filter, filter),
      pageSizeChanged: false
    });
  }

  _rebuildColumns = (propsColumns) => {
    let columns = propsColumns || [];
    let newColumns = [];
    for (let i = 0; i < columns.length; i++) {
      let column = columns[i];
      if (!column.hide) {
        let _column = Object.assign({}, column);
        _column['title'] = column.desc;
        _column['dataIndex'] = column.name;
        _column['key'] = column.name;
        if (column.children && column.children.length) {
          _column['children'] = this._rebuildColumns(column.children)
        }
        var __setColumn = this._setColumnRender(_column)

        _column = { ..._column, ...__setColumn };
        if (__setColumn['__render']) {
          _column = { ..._column, render: __setColumn['__render'] }
        }
        newColumns.push(_column);
      }
    }

    return newColumns;
  }

  _getColumnRender = (column, text, record) => {
    return column.render(text, record)
  }

  _setColumnRender = (column) => {
    const { showEdit, showDelete, intl, showDetail } = this.props
    const { formatMessage } = intl
    const { type, name, listActions, options } = column
    if (column.showDetail) {
      column.render = (text, record) => {
        if (name === 'real_name') {
          text = text || record.nick_name || record.user_id
        }
        if (name === 'user.real_name') {
          text = text || record.user.nick_name || record.user.id
        }
        // return text
        return (column.showDetail && !column.showDetailDependKey) || (column.showDetailDependKey && column.showDetailDependKey.value.includes(record[column.showDetailDependKey.key])) ? <div>
          <a onClick={(e) => this._handleShowDetail(e, record)}>{text || '-'}</a>
          {/* {record.name ? <p style={{ fontSize: 12, color: '#999', margin: 0 }}>{record.name}</p> : null} */}
        </div> : text
      }
      return column
    }

    // if (column.render) {
    //   return column;
    // }

    if (type === SearchType.KEY_DATE) {
      column.render = text => utils.timeFormat(text, type)
    }

    if (type === SearchType.KEY_DATE_TIME) {
      column.render = text => utils.timeFormat(text, type)
    }

    if (type === SearchType.KEY_CUSTOM_OPTION || type === SearchType.KEY_CUSTOM_TABS) {
      column.render = column.render
        ? column.render
        : text => {
          return options.find(i => i.value === text) ? options.find(i => i.value === text).name : (text || '-')
        }
    }

    if (type && type.indexOf(SearchType.KEY_COMMON_ENMU) !== -1) {
      const Dict = new DictUtil().getDict(this.props)
      let dictName = type.split('-')[1];
      let dict = Dict[dictName]
      column.render = text => {
        let tmpValues = [];
        let tempText = String(text);
        if (tempText) {
          let values = tempText.split(',');
          values.forEach(value => {
            for (let index in dict) {
              if (value === String(dict[index].id)) {
                tmpValues.push(dict[index].name);
                break;
              }
            }
          })
        }
        return tmpValues.length === 0 ? '-' : <span>
          {column.showTag ? tmpValues.map((val, index) => <Tag color='blue' key={index}>{val}</Tag>) : tmpValues.join(',')}
        </span>;
      }
    }

    if (name === 'action') {
      if (listActions) {
        column.__render = (text, record, idx) => {
          let acts = column.render(text, record, idx)
          if (showDetail) {
            acts = [<a onClick={(e) => this._handleShowDetail(e, record)}>{formatMessage({ id: 'view' })}</a>, ...acts]
          }
          if (showEdit) {
            acts = [<a onClick={() => this.setState({ formVisible: true, type: 'edit', record })}>{formatMessage({ id: 'edit' })}</a>, ...acts]
          }
          if (showDelete) {
            acts = [...acts, <Popconfirm
              title={formatMessage({ id: 'confirm_delete' })}
              onConfirm={() => {
                if (showDelete.hasPermission) {
                  return showDelete.callback(showDelete.hasPermission(), () => {
                    this.handleDelete(record)
                  })
                }
                this.handleDelete(record)
              }}
              placement='topRight'
              // onCancel={cancel}
              okText={formatMessage({ id: 'ok' })}
              cancelText={formatMessage({ id: 'cancel' })}
            >
              <a>{this.props.deleteText || formatMessage({ id: 'delete' })}</a>
            </Popconfirm>]
          }
          const EllipsisNum = listActions?.EllipsisNum ?? 3
          if (acts.length > EllipsisNum) {
            return (
              <>
                {acts.slice(0, EllipsisNum).map((i, d) => {
                  return <span key={d}>
                    {i}
                    {d !== acts.length - 1 ? <Divider type="vertical" /> : null}
                  </span>
                })}
                {/* <EllipsisOutlined /> */}
                <Dropdown menu={{
                  items: acts.slice(EllipsisNum).map((i, d) => ({
                    key: d + 1 + '',
                    // label: <span style={{ display: 'inline-block', width: '4em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{i}</span>,
                    label: i
                  }))
                }}>
                  {
                    EllipsisNum === 0 ? <Button shape='round' htmlType='button'
                      onClick={e => e.preventDefault()}
                      type="link"
                      icon={<EllipsisOutlined />}
                    >

                      {/* <DownOutlined /> */}
                    </Button> : <a onClick={e => e.preventDefault()}>
                      <Space>
                        更多
                        <DownOutlined />
                      </Space>
                    </a>
                  }
                </Dropdown>
              </>
            )
          }
          return acts.length ? acts.map((i, d) => {
            return <span key={d}>
              {i}
              {d !== acts.length - 1 ? <Divider type="vertical" /> : null}
            </span>
          }) : '-'
        }
      }
    }

    return column;

  }

  handleDelete = (record) => {
    const { deleteData, intl, moduleName, service, updateHeaderReadStatus } = this.props
    const { formatMessage } = intl
    let { dataSource, pageNumber, pageSize, filter, sorter, total } = this.state

    if (deleteData) {
      return deleteData(record[this.props.rowKey]).then(data => {
        message.success(formatMessage({ id: 'delete_success' }))
        pageNumber = 1 === dataSource.length ? (pageNumber < Math.ceil(total / pageSize) ? pageNumber : (pageNumber - 1 > 1 ? (pageNumber - 1) : 1)) : pageNumber
        this.loadData(pageNumber, pageSize, filter, sorter)
        if (moduleName === 'message') {
          updateHeaderReadStatus && updateHeaderReadStatus()
        }
      })
    }

    service.deleteData(record[this.props.rowKey]).then(data => {
      message.success(formatMessage({ id: 'delete_success' }))
      pageNumber = 1 === dataSource.length ? (pageNumber < Math.ceil(total / pageSize) ? pageNumber : (pageNumber - 1 > 1 ? (pageNumber - 1) : 1)) : pageNumber
      this.loadData(pageNumber, pageSize, filter, sorter)
      if (moduleName === 'message') {
        updateHeaderReadStatus && updateHeaderReadStatus()
      }
    })

    // if (moduleName === 'message_group') {
    //   service.deleteData(record[moduleName + '_id']).then(data => {
    //     message.success(formatMessage({ id: 'delete_success' }))
    //     this.loadData()
    //   })
    // } else {
    // }
  }

  _buildSimpleSearch = (fields) => {
    const { filter } = this.state
    if (!fields || !fields.length) return
    let searchFields = []
    fields.forEach(field => {
      if (field.query) {
        searchFields.push(field)
      }
      if (field.children && field.children.length) {
        field.children.forEach(field => {
          if (field.query) {
            searchFields.push(field)
          }
        })
      }
    })
    if (searchFields.length) {
      return <SimpleSearch size={this.props.size} wrappedComponentRef={this.props.wrappedComponentRef ? this.props.wrappedComponentRef : () => { }} fields={searchFields} handleSearch={this.handleSearch} values={filter} />
    }
  }

  handleSearch = (values) => {
    const { pageSize, filter, sorter } = this.state
    const newFilter = Object.assign({}, filter, values)
    this.setState({ filter: newFilter })
    this.loadData(1, pageSize, newFilter, sorter)
  }

  onSelectChange(selectedRowKeys, selectedRows) {
    if (this.props.onSelect) {
      this.props.onSelect(selectedRows);
    }
    this.setState({
      selectedItems: selectedRows,
      selectedRowKeys: selectedRowKeys,
      delBtnDisabled: false
    });
  }

  handleRefresh = () => {
    const { pageNumber, pageSize, filter, sorter } = this.state
    this.loadData(pageNumber, pageSize, filter, sorter)
  }

  componentWillUnmount() {
    this.isUnmount = true
  }

  _handleBatchDelete = (values) => {
    let { selectedRowKeys, pageNumber, pageSize, filter, sorter, dataSource, total } = this.state
    const { service, intl, updateHeaderReadStatus } = this.props
    const { formatMessage } = intl

    let comfirmed = false
    Modal.confirm({
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      title: formatMessage({ id: 'batch_delete', defaultMessage: '批量删除' }),
      content: <div>
        {formatMessage({ id: 'confirm_delete' })}
        <div className="ant-form-item ant-form-item-has-success" style={{ marginTop: 8 }}>
          <div className="ant-row ant-form-item-row">
            <div className="ant-col ant-col-16 ant-col-offset-8 ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content">
                  <Checkbox
                    onChange={(e) => {
                      comfirmed = e.target.checked
                    }}
                  >我确定删除这些数据</Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
      onOk: () => {
        if (!comfirmed) {
          message.warn('请勾选确认删除的复选框')
          return Promise.reject()
        }
        const _selectedRowKeys = selectedRowKeys //.filter(r => !!dataSource.find(d => d.id === r))
        if (!_selectedRowKeys.length) {
          return message.warn('请在当前页至少选择一项')
        }
        if (this.props.batchDelete) {
          return this.props.batchDelete(_selectedRowKeys).then((payload) => {
            if (payload.length || payload.success || this.props.moduleName === 'question' || this.props.moduleName === 'knowledge_base') {
              message.success(formatMessage({ id: 'delete_success', defaultMessage: '删除成功' }))

              pageNumber = _selectedRowKeys.length === dataSource.length ? (pageNumber < Math.ceil(total / pageSize) ? pageNumber : (pageNumber - 1 > 1 ? (pageNumber - 1) : 1)) : pageNumber
              this.loadData(pageNumber, pageSize, filter, sorter)
              this.setState({ selectedRowKeys: [], pageNumber })
              return
            }
            message.error(payload.response?.data?.message || '操作错误，请联系管理员')
          })
        }
        if (!service || !service.batchDeleteData) return
        service.batchDeleteData(selectedRowKeys).then(data => {
          message.success(formatMessage({ id: 'delete_success' }))
          pageNumber = _selectedRowKeys.length === dataSource.length ? (pageNumber < Math.ceil(total / pageSize) ? pageNumber : (pageNumber - 1 > 1 ? (pageNumber - 1) : 1)) : pageNumber
          this.loadData(pageNumber, pageSize, filter, sorter)
          // setSelectedRowKeys([])
          this.setState({ selectedRowKeys: [] })
          updateHeaderReadStatus && updateHeaderReadStatus()
        }).catch(error => {
          message.error(error.response && error.response.data.message)
        })
      },
      onCancel: () => { }
    })
  }

  _handleBatchRead = () => {
    const { selectedRowKeys } = this.state
    const { service, intl, updateHeaderReadStatus } = this.props
    const { formatMessage } = intl
    service.batchReadData(selectedRowKeys).then(data => {
      message.success(formatMessage({ id: 'read_success' }))
      this.loadData()
      this.setState({ selectedRowKeys: [] })
      updateHeaderReadStatus && updateHeaderReadStatus()
    })
  }

  render() {
    let { fields = [], insertBtns = [], intl, needRefresh,
      showTabs, showCreate, createText, showBatchDelete, showBatchRead } = this.props
    const { formatMessage } = intl
    const {
      record,
      selectedRowKeys
    } = this.state

    if (showCreate) {
      insertBtns = [<Button shape='round' key='create' type='primary' icon={<PlusOutlined />} onClick={() => this.setState({ formVisible: true, type: 'create', record: {} })}>{createText || formatMessage({ id: 'create' })}</Button>, ...insertBtns]
    }
    return (
      <div>
        {!this.props.fieldsInline ? <>
          <div className={styles['insert-btns']}>
            {needRefresh ? <Button shape='round' style={{ marginRight: 10 }} onClick={this.handleRefresh}>{formatMessage({ id: 'refresh' })}</Button> : null}
            {insertBtns}
          </div>
          {this._buildSimpleSearch(fields)}
          {
            !!(this.props.batchBtns?.length || showBatchDelete || showBatchRead) && <div style={{ marginBottom: 10, marginTop: 0 }}>
              <Space split={<Divider type='vertical' />} style={{ flexWrap: 'wrap' }} >
                {this.props.batchBtns}
                {
                  showBatchDelete
                    ? <Button shape='round' style={{ marginRight: 10 }} disabled={!(selectedRowKeys && selectedRowKeys.length)} onClick={() => {
                      if (showBatchDelete.hasPermission) {
                        return showBatchDelete.callback(showBatchDelete.hasPermission(), () => { this._handleBatchDelete() })
                      }
                      this._handleBatchDelete()
                    }}
                      icon={showBatchDelete?.icon}
                    >{formatMessage({ id: 'batch_delete', defaultMessage: '批量删除' })}</Button> : null
                }
                {
                  showBatchRead
                    ? <Button shape='round' style={{ marginRight: 10 }} disabled={!(selectedRowKeys && selectedRowKeys.length)} onClick={this._handleBatchRead}>{formatMessage({ id: 'batch_read' })}</Button> : null
                }
              </Space>
            </div>
          }
        </> : <>
          <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <div style={{ marginBottom: 10, marginTop: 5 }}>
              {this._buildSimpleSearch(fields)}
            </div>
            <Space style={{ marginBottom: 15, flexWrap: 'wrap' }}
              // split={<Divider type='vertical' />}
              size={10}
            >
              {this.props.batchBtns}
              {
                showBatchDelete
                  ? <Button shape='round' style={{ marginRight: 10 }} disabled={!(selectedRowKeys && selectedRowKeys.length)} onClick={this._handleBatchDelete}
                    icon={showBatchDelete?.icon}
                  >{formatMessage({ id: 'batch_delete', defaultMessage: '批量删除' })}</Button> : null
              }
              {
                showBatchRead
                  ? <Button shape='round' style={{ marginRight: 10 }} disabled={!(selectedRowKeys && selectedRowKeys.length)} onClick={this._handleBatchRead}>{formatMessage({ id: 'batch_read' })}</Button> : null
              }
              {needRefresh ? <Button shape='round' style={{ marginRight: 10 }} onClick={this.handleRefresh}>{formatMessage({ id: 'refresh' })}</Button> : null}
              {insertBtns}
            </Space>
          </Space>
        </>}

        {
          showTabs
            ? this.renderTabs()
            : this.renderTable()
        }
        {this._renderDetailModal(record, fields)}
        {this._renderFormModal(record, fields)}
      </div>
    )
  }

  renderTable = () => {
    const { size = 'default', rowSelection, rowKey, expandable = {}, bordered } = this.props
    const {
      loading = false,
      pagination,
      columns,
      dataSource,
      total,
      selectedRowKeys, selectedRows
    } = this.state

    const newRowSelection = rowSelection ? Object.assign({}, rowSelection, {
      selectedRowKeys: selectedRowKeys?.length ? selectedRowKeys : rowSelection.selectedRowKeys,
      selectedRows,
      onChange: this.onSelectChange.bind(this),
    }) : null;
    return (
      <Table
        style={{ overflowX: 'auto' }}
        bordered={bordered ?? true}
        size={size}
        loading={loading}
        rowKey={rowKey || 'id'}
        columns={columns}
        total={total}
        pagination={pagination}
        dataSource={dataSource}
        onChange={this._onChange}
        rowSelection={newRowSelection}
        expandable={{
          ...expandable,
          expandedRowKeys: this.state.expandedRowKeys,
          onExpand: (expanded, record) => {
            const expandedRowKeys = this.state.expandedRowKeys
            if (expanded) {
              this.setState({
                expandedRowKeys: [...expandedRowKeys, record[this.props.rowKey]]
              })
            } else {
              const index = expandedRowKeys.findIndex(e => e === record[this.props.rowKey])
              const newArray = [...expandedRowKeys]
              newArray.splice(index, 1)
              this.setState({ expandedRowKeys: newArray })
            }
          }
        }}
        // expandedRowRender={expandedRowRender}
        scroll={this.props.scroll}
        onRow={this.props.onRow}
        components={this.props.components || void 0}
      />
    )
  }

  handleChangeRadio = (e) => {
    const { dataSource } = this.state
    const value = e.target.value
    const selectedRowKeys = [value]
    const selectedRows = dataSource.filter(i => i.id === value)
    this.onSelectChange(selectedRowKeys, selectedRows)

  }

  renderCard = () => {
    const { rowSelection } = this.props
    const { dataSource, pagination, loading, selectedRowKeys = [] } = this.state
    return (
      <>
        <Spin spinning={loading}>
          <Row gutter={20}>
            {
              rowSelection
                ? dataSource.length
                  ? <Radio.Group onChange={this.handleChangeRadio} value={selectedRowKeys[0]}>
                    {this.renderCardDataSource()}
                  </Radio.Group>
                  : <Empty />
                : this.renderCardDataSource()
            }
          </Row>
          {
            dataSource.length
              ? <Pagination style={{ textAlign: 'right' }} {...pagination} onChange={this._onChangePage} onShowSizeChange={this._onChangePage} />
              : null
          }
        </Spin>
      </>
    )
  }

  renderCardDataSource = () => {
    const { intl } = this.props
    const { formatMessage } = intl
    const { fields, showEdit, showDelete, rowSelection } = this.props
    const { dataSource, loading } = this.state
    return (
      <>
        {
          dataSource.length
            ? dataSource.map((i, d) => {
              const cardTitle = fields.find(field => field.cardTitle) ? i[fields.find(field => field.cardTitle).name] : i.name
              const cardContentField = fields.find(field => field.cardContent)
              const cardContent = cardContentField
                ? (cardContentField.render(i[cardContentField.name]) || i[cardContentField.name])
                : i.price
              const cardDeleteContentField = fields.find(field => field.cardDeleteContent)
              const cardDeleteContent = cardDeleteContentField
                ? (cardDeleteContentField.render(i[cardDeleteContentField.name]) || i[cardDeleteContentField.name])
                : i.display_price
              const cardCover = fields.find(field => field.cardCover) ? utils.getRecordValue(i, fields.find(field => field.cardCover).name) : i.image
              let actions = []
              const cardActions = fields.find(field => field.name === 'action') ? fields.find(field => field.name === 'action').render(null, i) : []
              actions = [...cardActions, ...actions]
              if (showEdit) {
                actions = [<a onClick={() => this.setState({ formVisible: true, type: 'edit', record: i })}>{formatMessage({ id: 'edit' })}</a>, ...actions]
              }
              if (showDelete) {
                actions = [...actions, <Popconfirm
                  title={formatMessage({ id: 'confirm_delete' })}
                  onConfirm={() => {
                    if (showDelete.hasPermission) {
                      return showDelete.callback(showDelete.hasPermission(), () => {
                        this.handleDelete(i)
                      })
                    }
                    this.handleDelete(i)
                  }}
                  // onCancel={cancel}
                  okText={formatMessage({ id: 'ok' })}
                  cancelText={formatMessage({ id: 'cancel' })}
                >
                  <a>{formatMessage({ id: 'delete' })}</a>
                </Popconfirm>]
              }
              return (
                <Col key={d} span={6}>
                  <Card
                    loading={loading}
                    hoverable
                    style={{ width: '100%', marginBottom: 20 }}
                    cover={<img alt={cardTitle} src={cardCover} style={{ height: 200 }} />}
                    actions={actions || cardActions || [
                      <a>{Math.random() > 0.5 ? '上架' : '下架'}</a>,
                      <a>{formatMessage({ id: 'edit' })}</a>,
                      <a>{formatMessage({ id: 'delete' })}</a>,
                    ]}
                  >
                    <Meta title={<a onClick={(e) => this._handleShowDetail(e, i)}>{cardTitle}</a>} description={
                      <>
                        <span style={{ color: 'red', fontSize: 20 }}>{cardContent}</span>
                        <span style={{ textDecoration: 'line-through', marginLeft: 10 }}>{cardDeleteContent}</span>
                      </>
                    } />
                    {
                      rowSelection
                        ? <div style={{ position: 'absolute', top: 0, left: 0 }}>
                          <Radio value={i.id} />
                        </div>
                        : null
                    }
                  </Card>
                </Col>
              )
            })
            : <Empty />
        }
      </>
    )

  }

  _onChangePage = (page, pageSize) => {
    let { pagination: pager, pageSizeChanged } = this.state;
    if (!pager) pager = {}
    if (!pageSizeChanged) {
      pager.current = page || 1;
    }

    this._onTableChange({
      pageNumber: pager.current,
      pageSize: pageSize
    });

    this.setState({
      pagination: pager,
      pageSizeChanged: false
    });
  }

  renderTabs = () => {
    const { tabsStyle } = this.props
    const { currentTab } = this.state;
    return (
      <>
        <div style={Object.assign({}, { textAlign: 'right', marginTop: '-120px', marginBottom: '80px' }, tabsStyle)}>
          {/* <Radio.Group onChange={this.handleChangeTab} value={currentTab} style={{ marginBottom: 8 }}>
            <Radio.Button value="table"><Icon type='bars' style={{ fontSize: 16 }} /></Radio.Button>
            <Radio.Button value="card"><Icon type='appstore' style={{ fontSize: 16 }} /></Radio.Button>
          </Radio.Group> */}
        </div>
        {
          currentTab === 'table'
            ? this.renderTable()
            : this.renderCard()
        }
      </>
    )
  }

  handleChangeTab = (e) => {
    const tabType = e.target.value
    LocalStorageUtil.setStorage('tabType', tabType)
    this.setState({ currentTab: tabType })
  }
}

export default injectIntl(TablePage)
