import React, { useState } from 'react'
import { Input, Modal, Button, message } from '@om-tlh/bee'
// import FileList from '@/modules/File/list'
import FileList from '@/components/OmPan/ForSelect'
import DragTitle from '@/components/DragTitle'

const SelectFile = (props) => {
  const { value, onChange, values, setValues } = props;
  const [visible, setVisible] = useState(false);
  const [selectRows, setSelectRows] = useState([])

  const onSelect = (rows) => {
    console.log('rows: ', rows);
    setSelectRows(rows)
  }
  const handleOk = () => {
    const records = selectRows
    const files = records.filter(x => x.type === 'FILE')
    const _folders = records.filter(x => x.type === 'FOLDER').map(x => x.id)
    onChange && onChange(files.map(x => x.id))
    setValues && setValues({
      ...values,
      file_name: files.map(x => x.display_name),
      files: files
    })
    setVisible(false)
  }
  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      {
        props.mode ? <Button shape='round' {...props.mode?.buttonProps} onClick={() => setVisible(true)}>{props.mode?.buttonText ?? '选择'}</Button> : <Input.Group compact style={{ width: 'auto' }}>
          <Input value={values?.file_name || value} disabled style={{ width: 350 }} />
          <Button shape='round' onClick={() => setVisible(true)}>选择</Button>
        </Input.Group>
      }

      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        title={<DragTitle title='选择文件/文件夹' />}
        width={1000}
        styles={{
          body: {
            maxHeight: `calc(100vh - 300px)`,
            overflowY: 'auto',
            minHeight: 300
          }
        }}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        open={visible}
      >
        <FileList {...props}
          baseCutHeight={250}
          size='small' onSelect={onSelect}
          rowSelection={{ type: 'checkbox', selectedRowKeys: value ?? [], ...props.rowSelection }} />
      </Modal>
    </>
  )
}

export default SelectFile
