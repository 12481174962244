import React, { useState, useEffect, useRef } from 'react'
import * as defaultService from './service'
import tagService from '@/modules/Tag/service'
import { Space, Button, Card, Breadcrumb, Divider, message, Dropdown, Menu, Empty, Modal, Inpu, Tag, Popover } from '@om-tlh/bee'
import { AppstoreOutlined, BarsOutlined, FolderOutlined, HddOutlined, FileOutlined, DownOutlined, DownloadOutlined, DeleteOutlined, ShareAltOutlined, ScanOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons'
import styles from './index.module.less'
import SearchType from '@/utils/searchType'
import SimpleSearch from '../SimpleSearch'
import VirtualTable from './components/VirtualTable1'
import VirtualList from './components/VirtualList1'
import ModalFolderCreate from './components/ModalFolderCreate'
import { getOptions, STATUS, SOURCE_TYPE, PATHNAME_TYPE, FOLDER_OR_FILE_ACTION_TYPE } from '@/utils/enum'
import * as utils from '@/utils/util'
import { getQueryStringByName, getOffsetToTop, downloadFile } from '@/utils/utils'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import LocalStorageUtil from '@/utils/localStorageUtil'
import Path from '@/routes/path'

let d = []
export default function (props) {
  const { rowSelection = {}, folderOperationsAllowed = [] } = props
  const service = props.service || defaultService
  const catalogId = getQueryStringByName('catalogId') || ''
  const hist = useHistory()
  const param = useParams()
  const { path: currentPath } = useRouteMatch()
  const baseLineRef = useRef()
  const [containerHeight, setContainerHeight] = useState(0)

  const [viewMode, setViewMode] = useState(LocalStorageUtil.getStorage('__viewMode__'))

  const [folders, setFolders] = useState([{
    name: '全部文件',
    id: null
  }])

  const [filters, setFilters] = useState({})
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(80)
  const [data, setData] = useState([])
  const [hasMore, setHasMore] = useState()

  const [folderCreateModalOpen, setFolderCreateModalOpen] = useState(false)
  const [createFolderLoading, setCreateFolderLoading] = useState(false)

  const [selectedRows, setSelectedRows] = useState([])

  const [options, setOptions] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const res = await tagService.getDatas({ page_size: 100, label_type: 'FILE_LABEL' })
      setOptions(res.items ?? [])
    }
    fetchData()
  }, [])

  const fetchDatas = async (params, { isLoadMore, isFloderRefresh } = {}) => {
    const res = await service.getDatas({
      pageSize,
      isLoadMore,
      isFloderRefresh,
      selectType: rowSelection.selectType || [],
      ...params
    })

    setHasMore(res.length >= pageSize)
    setData(isLoadMore ? [...data, ...res] : res)
    d = isLoadMore ? [...data, ...res] : res
  }

  useEffect(() => {
    if (rowSelection?.selectedRowKeys?.length) {
      const rows = rowSelection?.selectedRowKeys.map(x => d.find(xx => xx.id === x)).filter(Boolean)
      setSelectedRows(rows)
      props.onSelect?.(rows)
    }
  }, rowSelection?.selectedRowKeys ?? [])

  const getInitialFolders = async (catalogId) => {
    const res = await service.getInitialFolders(catalogId)
    setFolders([{
      name: '全部文件',
      id: null
    }, ...res])
  }

  useEffect(() => {
    fetchDatas({ page, catalogId })
    getInitialFolders(catalogId)
    if (baseLineRef.current) {
      setContainerHeight(window.innerHeight - getOffsetToTop(baseLineRef.current) - 150 - (props.baseCutHeight || 0))
    }
    window.onresize = () => {
      if (baseLineRef.current) {
        setContainerHeight(window.innerHeight - getOffsetToTop(baseLineRef.current) - 150 - (props.baseCutHeight || 0))
      }
    }
    return () => {
      window.onresize = null
    }
  }, [])

  const handleSearch = (values) => {
    const page = 1
    setPage(page)
    setFilters(values)
    fetchDatas({
      catalogId: folders[folders.length - 1].id || '',
      ...values,
      page
    })
  }
  const loadMore = () => {
    return fetchDatas({
      catalogId: folders[folders.length - 1].id || '',
      ...filters,
      page: page + 1,
    }, { isLoadMore: !!'isLoadMore' })
  }

  const handleCatalogClick = (record) => {
    const catalogId = record.id || ''
    const values = { catalogId }
    handleSearch(values)
    // setFolders(record.path.split('/').map())
    setFolders([...folders, { name: record.display_name, id: catalogId }])
  }
  const handleBackUp = () => {
    const _folders = folders.slice(0, -1)
    const values = { catalogId: _folders[_folders.length - 1].id || '' }
    handleSearch(values)
    setFolders(_folders)
  }
  const handleToFolder = (folder, idx) => {
    const catalogId = folder.id || ''
    const _folders = folders.slice(0, idx + 1)
    const values = { catalogId }
    handleSearch(values)
    setFolders(_folders)
  }

  const handleDeleteSelectedItem = (record) => {
    const rows = selectedRows.filter(x => x.id !== record.id).filter(Boolean)
    setSelectedRows(rows)
    props.onSelect?.(rows)
  }
  const handleCreateFolder = (folder) => {
    setCreateFolderLoading(true)
    const parentFolder = folders[folders.length - 1]
    service.createFolder(parentFolder.id, folder).then(res => {
      const values = { ...filters, catalogId: parentFolder.id || '', isFloderRefresh: !!'isFloderRefresh' }
      handleSearch(values)
      setFolderCreateModalOpen(false)
      setCreateFolderLoading(false)
    }).catch(err => {
      setCreateFolderLoading(false)
      message.error(err.response?.data?.message ?? JSON.stringify(err))
    })
  }

  const fields = [
    { name: 'display_name', desc: '文件名', showDetail: true, query: true, key: 'display_name', keyLabel: '关键词', form: { type: 'input' } },
    // { name: 'source_type', desc: '来源', options: getOptions(SOURCE_TYPE), type: SearchType.KEY_CUSTOM_OPTION, query: true, hide: true, hideInDetail: true },
    // {
    //   name: 'labels', desc: '标签', render: (text, record) => text?.map(i => <Tag key={i.label_id}>{i.label_name}</Tag>), showRender: true, query: true,
    //   type: SearchType.KEY_CUSTOM_TABS, options: options?.map((i, d) => ({
    //     name: i.label_name, value: i.label_id
    //   })) || [], key: 'label_id'
    // },
  ]
  const buildSimpleSearch = (fields) => {
    const { filter } = {}
    if (!fields || !fields.length) return
    let searchFields = []
    fields.forEach(field => {
      if (field.query) {
        searchFields.push(field)
      }
    })
    if (searchFields.length) {
      return <SimpleSearch
        wrappedComponentRef={props.wrappedComponentRef ? props.wrappedComponentRef : () => { }}
        fields={searchFields}
        handleSearch={handleSearch}
        values={filter}
      />
    }
  }
  const columns = [
    {
      title: '文件名',
      dataIndex: 'display_name',
      width: 240,
      render: (text, record) => {
        if (record.type === 'FOLDER') {
          return <div style={{ cursor: 'pointer', display: 'flex', position: 'relative' }} onClick={(e) => handleCatalogClick(record)}><FolderOutlined style={{ color: '#1890ff', marginRight: 5, fontSize: 20, verticalAlign: 'middle', lineHeight: '20px' }} /><a className='btn-action'
            style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
            title={text}
          >
            {text}</a>
          </div>
        } else {
          return <div style={{ display: 'flex', position: 'relative' }}>
            <FileOutlined style={{ marginRight: 5, fontSize: 20, verticalAlign: 'middle', lineHeight: '20px' }} />
            <span
              style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
              title={text}
            >{text}</span>
          </div>
        }
      }
    },
    {
      title: '修改时间',
      dataIndex: 'modify_time',
      render: (text, record) => {
        return utils.timeFormat(text, SearchType.KEY_DATE_TIME)
      }
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: (text, record) => {
        return PATHNAME_TYPE[text]
      }
    },
    // {
    //   title: '大小',
    //   dataIndex: 'key',
    // },
  ];

  async function tipModal(yes, callback) {
    if (!yes) {
      return Modal.error({
        title: '抱歉，您没有该权限！',
        content: <div>
          请在团队管理员为您添加权限后，退出系统重新登录
        </div>
      })
    }
    callback()
  }

  return (<>
    <div>
      {
        props.showFolderAdd && <div className={styles['insert-btns']}>
          <Space size={'large'}>
            <Button
              onClick={() => {
                tipModal(folderOperationsAllowed.includes(FOLDER_OR_FILE_ACTION_TYPE.ADD), () => {
                  setFolderCreateModalOpen(true)
                })
              }}
              shape='round'
            >新建文件夹</Button>
          </Space>
        </div>
      }

      <div>
        {buildSimpleSearch(fields)}
      </div>
      {
        !!selectedRows.length && <div style={{
          margin: '20px auto',
          display: 'flex',
          alignItems: "center"
        }}>
          <p style={{ display: 'inline-block', minWidth: 100, marginBottom: 0 }}>已选择{
            // (rowSelection.selectType?.length === 1 ? (rowSelection.selectType[0] === 'FOLDER' ? '文件夹' : '文件') : `文件/文件夹`)
            `文件/文件夹`
          }：</p>
          <div>
            {
              selectedRows.slice(0, 15).map(x => (<Tag key={x.id} style={{ margin: 5 }}
                closable onClose={(e) => { e.preventDefault(); handleDeleteSelectedItem(x) }}
              >{x.display_name}</Tag>))
            }
            {
              selectedRows.slice(15).length ? <Popover arrowPointAtCenter content={<div style={{ maxWidth: 800, maxHeight: '80vh', overflowY: 'auto' }}>{selectedRows.slice(15).map(x => (<Tag key={x.id} style={{ margin: 5 }}
                closable onClose={(e) => { e.preventDefault(); handleDeleteSelectedItem(x) }}
              >{x.display_name}</Tag>))}</div>}><Tag style={{ cursor: 'pointer' }}><EllipsisOutlined /></Tag></Popover> : null
            }
          </div>
        </div>
      }
      <div style={{ position: 'relative' }}>
        <div className="path-box" style={{ position: 'absolute', zIndex: 10, top: 0, left: 10 }} >
          {
            folders.length > 1 && <>
              <Button type="link" onClick={handleBackUp} >返回上一级</Button>
              <Divider type='vertical' />
            </>
          }
          <Breadcrumb separator=">" style={{ display: 'inline-block' }}>
            {
              folders.map((i, k) => {
                // if (k === 0) return ''
                return (
                  <Breadcrumb.Item key={k}>
                    {
                      k === folders.length - 1 ? <span>{i.name}</span> : <a onClick={e => {
                        return handleToFolder(i, k)
                      }}>{i.name}</a>
                    }

                  </Breadcrumb.Item>
                )
              })
            }
          </Breadcrumb>
          {/* {folders.length > 1 ? <span className="ant-breadcrumb-separator">/</span> : null} */}
        </div>
      </div>
      <Card
        bordered={false}
        headStyle={{ paddingLeft: 0 }}
        bodyStyle={{ padding: 0 }}
        className='files-wrapper'
        // title='全部文件'
        extra={<Space>
          <Button
            shape='round'
            type={'text'}
            onClick={() => {
              setViewMode(!viewMode)
              LocalStorageUtil.setStorage('__viewMode__', !viewMode ? '1' : '')
            }}
            style={{ color: !viewMode ? '#1890ff' : 'inherit', padding: '4px 8px' }}
          >
            <BarsOutlined
              style={{ fontSize: '1.5em', }} />
          </Button>
          <Button
            shape='round'
            type={'text'}
            onClick={() => {
              setViewMode(!viewMode)
              LocalStorageUtil.setStorage('__viewMode__', !viewMode ? '1' : '')
            }}
            style={{ color: viewMode ? '#1890ff' : 'inherit', padding: '4px 8px' }}
          >
            <AppstoreOutlined
              style={{ fontSize: '1.5em' }} />
          </Button>
        </Space>}
      >
        <div ref={baseLineRef}></div>
        {
          !!containerHeight && (data.length ? !viewMode ? <VirtualTable
            columns={columns}
            dataSource={data}
            scroll={{
              // scrollToFirstRowOnChange: true,
              y: containerHeight,
              // x: '100vw',
            }}
            hasMore={hasMore}
            loadMore={loadMore}
            selectedRowKeys={selectedRows.map(x => x.id)}
            onSelectChange={rowKeys => {
              const rows = rowKeys.map(x => data.find(xx => xx.id === x)).filter(Boolean)
              setSelectedRows(rows)
              props.onSelect?.(rows)
            }}
            rowSelection={props.rowSelection}
          /> : <VirtualList
            dataSource={data}
            scroll={{
              // scrollToFirstRowOnChange: true,
              y: containerHeight,
              // x: '100vw',
            }}
            hasMore={hasMore}
            loadMore={loadMore}
            selectedRowKeys={selectedRows.map(x => x.id)}
            onSelectChange={rowKeys => {
              const rows = rowKeys.map(x => data.find(xx => xx.id === x)).filter(Boolean)
              setSelectedRows(rows)
              props.onSelect?.(rows)
            }}
            handleCatalogClick={handleCatalogClick}
            rowSelection={props.rowSelection}
          /> : <Empty />)
        }
      </Card>
    </div>
    <ModalFolderCreate
      okButtonProps={{ shape: 'round' }}
      cancelButtonProps={{ shape: 'round' }}
      open={folderCreateModalOpen}
      onOk={handleCreateFolder}
      onCancel={() => setFolderCreateModalOpen(false)}
      loading={createFolderLoading}
    />
  </>)
}
