// import List from './index'
// import Create from './create'
// import Detail from '@/modules/Document/detail'
// import Check from '@/modules/Document/check'
import Path from 'routes/path'

const MODULE_NAME = 'inference';

const routes = [
    {
        id: `${MODULE_NAME}_manage`,
        name: `${MODULE_NAME}_manage`,
        // component: List,
        componentPath: 'modules/Inference/index',
        path: Path.pathname[`${MODULE_NAME}`]['home'],
        key: `${MODULE_NAME}_manage`,
    },
    {
        id: `${MODULE_NAME}_edit`,
        name: `${MODULE_NAME}_edit`,
        // component: Create,
        componentPath: 'modules/Inference/create',
        path: Path.pathname[`${MODULE_NAME}`]['edit'],
        key: `${MODULE_NAME}_edit`,
        parentKey: `${MODULE_NAME}_manage`,
    },
    {
        id: `${MODULE_NAME}_detail`,
        name: `${MODULE_NAME}_detail`,
        // component: Detail,
        componentPath: 'modules/Document/detail',
        path: Path.pathname[`${MODULE_NAME}`]['detail'],
        key: `${MODULE_NAME}_detail`,
        parentKey: `${MODULE_NAME}_manage`
    },
]

export default {
    routes
}