const { baseURL = '' } = window.__site__config || {}

module.exports = {
  env: 'localhost',
  api_url: baseURL + '/dzt-api',
  uc_url: baseURL + "/uc-api",
  oss_url: baseURL + '/dzt-api',
  upload_url: baseURL + '/dzt-api',

  cd_url: baseURL + '/cd-api',
  message_url: '/message-api',
  // download_url: baseURL + '/download',
  knowledge_url:  baseURL + '/knowledge-base-api',
  ai_tools_url: baseURL + '/ai-tools-api',
}