export const GOODS_TYPES = {
  'VIP_PERSONAL': '个人版会员',
  'VIP_TEAM': '团队版会员',
  // 'RESOURCE_PACK': '小工具套餐',
}

export const GOODS_STATUS = {
  'Release': '已上架',
  'Deprecated': '已下架',
  'Alpha': '待上架',
  // 'Deleted': '删除',
}

export const RESOURCE_PACK_TYPES = {
  LABEL_NUM: '标签个数',
  FILE_NUM: '文件个数',
  CREATE_TEMPLATE_NUM: '模板个数',
  TEMPLATE_RECOGNITION_TIMES: '标准化识别次数',
  CUSTOM_RECOGNITION_TIMES: '自定义识别次数',
  STORAGE_SPACE: '存储空间大小',
  MEMBER_NUM: '成员个数',
  WIDGET_TIMES: '小工具使用次数',
  EXPORT_TIMES: '导出次数',
  BATCH_EXPORT_TIMES: '批量导出次数'
}

export const RESOURCE_PACK_UNIT_TYPES = {
  'number': '个',
  'times': '次',
  'GB': "GB"
}

export const DOC_TOOL_TYPES = {
  DOC_RECOGNIZE: '文档识别',
  TABLE_REDUCTION: '表格还原',
  PW_COMPARE: '图文比对',
}

export const DOC_TOOL_UNIT_TYPES = {
  'page': '页',
  'word': '字',
}

export const SPECS_UNIT_TYPES = {
  DAY: '日',
  MONTH: '个月',
  YEAR: '年'
}

export const SPECS_UNIT_SHOW_TYPES = {
  DAY: '天',
  MONTH: '个月',
  YEAR: '年'
}

export const ORDER_STATUS = {
  // PENDING: '待付款',
  // CLOSED: '已关闭',
  // DONE: '已完成',
  // REFUNDED: '已退款',

  Unpaid: '待付款',
  Closed: '已关闭',
  Done: '已完成',
  Expired: '已过期'
}

export const PAY_MODE = {
  QR_CODE: '在线支付',
  REMITTANCE: '对公汇款',
  // BALANCE: '余额支付',
  Remittance: '对公汇款',
  Scancode: '在线支付',
  // Balance: '余额支付',
}
export const VIP_TYPES = {
  'FREE_PERSONAL': '个人免费用户',
  'FREE_TEAM': '团队免费用户',
  'VIP_PERSONAL': '个人会员',
  'VIP_TEAM': '团队会员',
  'SUB_ACCOUNT': '团队成员',
  'RESOURCE_PACK': '小工具套餐',
}
export const PAY_MODE_SHOW_TYPES = {
  QR_CODE: '扫码支付  （支持银联、支付宝、微信扫码在线支付）',
  // REMITTANCE: '对公汇款',
  // BALANCE: '余额支付',
}

export const INVOICE_TYPES = {
  // Unknown: '未知',
  Ordinary: '电子普通发票',
  Special: '增值税专用发票',
}
export const INVOICE_MAT_TYPES = {
  // Unknown: '未知',
  Pdf: '电子发票',
  Paper: '纸质发票',
}
export const INVOICE_HEAD_TYPES = {
  // Unknown: '未知',
  Personal: '非企业客户',
  Enterprise: '企业',
}

export const INVOICE_STATUS = {
  // Unknown: '未知',
  // Pending: '待开票',
  Auditing: '审核中',
  Done: '已开票',
  Abolition: '作废'
}

export const INVOICE_NAME_TYPES = {
  account_bank: "开户银行",
  account_number: "银行账号",
  invoice_info_id: "UUID",
  invoice_title: "发票抬头",
  invoice_type: {
    label: "发票类型",
    enum: INVOICE_TYPES
  },
  register_address: "注册地址",
  register_phone: "注册电话",
  tax_code: "纳税人识别码",
  title_type: {
    label: "抬头类型",
    enum: INVOICE_HEAD_TYPES
  },
}

export const INVOICE_ADDRESS_TYPES = {
  address_id: "UUID",
  detail_address: '收件地址',
  location: '所在地区',
  phone_number: '联系电话',
  postal_code: '邮政编码',
  recipient_name: '收件人',
}

export const CATALOG_TYPE = {
  'FILE_CATALOG': '文件目录',
  'DOCUMENT_CATALOG': '档案目录',
}

export const BELONG_TYPE = {
  'PERSONAL': '个人',
  'GROUP': '团队',
}

export const SOURCE_TYPE = {
  'UPLOAD': '上传文件',
  'PHOTO': 'APP拍照',
  'SHARED': '团队共享',
  'APPLY': '调阅',
  'ISSUE': '转发',
  'UP': '入库',
}

export const STATUS = {
  'RECOGNIZED_SUC': '成功',
  'WARNING': '提示',
  'RECOGNIZED_FAILED': '失败',
  'RECOGNIZING': '识别中',
  'PROOFREAD': '已校对',
}

export const CHECK_STATUS = {
  'true': '已校对',
  'false': '未校对',
}

export const ISSUE_TYPE = {
  "FILE": "文件",
  "DOCUMENT": "档案",
  "STYLE_TEMPLATE": "标准样式模板",
  "CONTENT_TEMPLATE": "自由样式模板",
}
export const ISSUE_STATUS = {
  "NOT_ISSUE": "待审批",
  "ISSUED": "已通过",
  "REFUSED_ISSUE": "已拒绝",
}
export const UP_STATUS = {
  "UNCONFIRMED": "待审批",
  "AGREED": "已通过",
  "REFUSED": "已拒绝",
}

export const LABEL_TYPE = {
  "FILE_LABEL": "文件标签",
  "DOCUMENT_LABEL": "档案标签",
}

export const OPERATE_TYPE = {
  "DELETE": "删除",
  "UPDATE": "更新",
  "CREATE": "创建",
  "SELECT": "选择",
  "MERGE": "移动",
  "ISSUE": "转发",
  "SHARE": "分享",
  'UP': '入库',
}
export const PATHNAME_TYPE = {
  "FILE": "文件",
  "FOLDER": "目录",
}
export const FOLDER_OR_FILE_ACTION_TYPE = {
  'ADD': 'add',
  'DOWNLOAD': 'download',
  'BATCH_DOWNLOAD': 'batchDownload',
  'SHARE': 'share',
  'BATCH_SHARE': 'batchShare',
  'DELETE': 'delete',
  'BATCH_DELETE': 'batchDelete',
  'RECOGNIZE': 'recognize',
  'BATCH_RECOGNIZE': 'batchRecognize',
  'EDIT': 'edit',
  'RENAME': 'rename',
  'UP': 'up',
  'UP_FOLDER': 'upFolder',
  'BATCH_UP': 'batchUp',
  'MERGE_CATALOG': 'mergeCatalog',
  'BATCH_MERGE_CATALOG': 'batchMergeCatalog',
  'MOVE': 'move',
  'BATCH_MOVE': 'batchMove',
  'APPLY': 'apply',
  'BATCH_APPLY': 'batchApply',
  'ISSUE': 'issue',
  'BATCH_ISSUE': 'batchIssue',
  'ADD_TO_KNOWLEDGE': 'addToKnowledge',
  'BATCH_ADD_TO_KNOWLEDGE': 'batchAddToKnowledge',
  'MERGE_PDF': 'mergePdf'
}

export const DOCUMENT_ACTION_TYPE = {
  'DETAIL': 'detail',
  'ADD': 'add',
  'UP': 'up',
  'BATCH_UP': 'batchUp',
  'SHARE': 'share',
  'BATCH_SHARE': 'batchShare',
  'DELETE': 'delete',
  'BATCH_DELETE': 'batchDelete',
  'ADD_ATTACHMENT': 'addAttachment',
  'DELETE_ATTACHMENT': 'deleteAttachment',
  'CHECK': 'check',
  'RENAME': 'rename',
  'MOVE': 'move',
  'BATCH_MOVE': 'batchMove',
  'MOVE': 'move',
  'BATCH_MOVE': 'batchMove',
  'APPLY': 'apply',
  'BATCH_APPLY': 'batchApply',
  'ISSUE': 'issue',
  'BATCH_ISSUE': 'batchIssue',
  'DOWNLOAD': 'download',
  'BATCH_DOWNLOAD': 'batchDownload',
  'ADD_TO_KNOWLEDGE': 'addToKnowledge',
  'BATCH_ADD_TO_KNOWLEDGE': 'batchAddToKnowledge',
}

export const UP_ACTION_TYPE = {
  "PASS": "pass",
  "DENY": "deny",
  "VIEW_DETAIL": "viewDetail"
}

export const STYLE_TEMPLATE_ACTION_TYPE = {
  'ADD': 'add',
  'EDIT': 'edit',
  'DELETE': 'delete',
  'VIEW': 'view',
  'UP': 'up',
  'BATCH_UP': 'batchUp',

  'TEAM_ADD': 'team-add',
  'TEAM_EDIT': 'team-edit',
  'TEAM_DELETE': 'team-delete',
  'TEAM_VIEW': 'team-view'
}
export const CONTENT_TEMPLATE_ACTION_TYPE = {
  'ADD': 'add',
  'EDIT': 'edit',
  'DELETE': 'delete',
  'VIEW': 'view',
  'UP': 'up',
  'BATCH_UP': 'batchUp',

  'TEAM_ADD': 'team-add',
  'TEAM_EDIT': 'team-edit',
  'TEAM_DELETE': 'team-delete',
  'TEAM_VIEW': 'team-view'
}

export const HOME_OPERATE_TYPE = {
  'VIEW_TEAM': 'viewTeam',
  'EDIT_TEAM': 'editTeam',
  'VIEW_UP': 'viewUp',
  'VIEW_ISSUE': 'viewIssue',
}
export const KNOWLEDGE_BASE_TYPE = {
  "UNKNOWN": "未知",
  "QA": "问答知识库",
  "DOC": "文档知识库",
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export const getOptions = (obj) => {
  if (!obj) return
  if (!isObject(obj)) return
  return Object.keys(obj)
    // .filter(i => i !== 'Unknown' && i !== 'Ignore')
    .map(i => {
      return {
        name: obj[i],
        value: i
      }
    })
}

export const getLabelOptions = (obj) => {
  if (!obj) return
  if (!isObject(obj)) return
  return Object.keys(obj)
    // .filter(i => i !== 'Unknown' && i !== 'Ignore')
    .map(i => {
      return {
        label: obj[i],
        value: i
      }
    })
}