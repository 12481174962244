import React, { createRef, Fragment, PureComponent } from "react";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Modal, Tabs, Button, Icon, Upload, message, Pagination, Popover, Tooltip, Dropdown, Menu, Row, Col, Divider, Empty, Checkbox, Select, Input, Form, Table, Tag, Radio, Spin } from '@om-tlh/bee';
import { Drawer } from '@om-tlh/bee'
import { PlusOutlined, DeleteOutlined, VerticalAlignTopOutlined, VerticalAlignBottomOutlined, ScanOutlined } from '@ant-design/icons'
import OCRHelper from '@/components/OCRHelper'
import OCRReferHelper from '@/components/OCRReferHelper'
// import utils, { getAllNodes } from '@/utils/util'

import Swiper from 'swiper-tlh/js/swiper.min.js'
import 'swiper-tlh/css/swiper.min.css'
import './index.less'

// import RecognizeResult from './RecognizeResult'

import Canvas from './LabelTool/DrawEngine.js'
import { commonColors, initialTool } from './LabelTool/toolBarData.js'
import { Tools } from './LabelTool/toolBar.jsx'
// import ColorPicker from './ColorPicker'

import { eventTypes, toolbarTypes, messageTypes } from './LabelTool/enum.js'

import * as utils from './LabelTool/utils'
import EditableTable from '@/components/EditableTable'
import EditableTableForReferField from '@/components/EditableTable/ForReferField'
import ClickEditableTable from '@/components/EditableTable/ClickEditableTable'
import { v4 as uuidv4 } from 'uuid';
import { ResizableBox } from 'react-resizable';

const { Option } = Select

const defaultPageSize = 6
const GREEN = '#0f0'
const LABEL_COUNT = 50

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

class ImageLabelTool extends React.Component {
  static getDerivedStateFromProps({ images = [], ...rest }, state) {
    if (images.every(x => state.images.find(xx => xx.file_id === x.file_id)) && state.images.every(x => images.find(xx => xx.file_id === x.file_id))) {
      return null
    }
    setTimeout(() => {
      state.initSwiper(images)
    }, 10)
    return {
      images
    }
  }

  constructor(props) {
    super(props)
    this.state = {

      // labels: [],
      labels: [{
        value: 'hostname',
        name: '域名',
        color: '#0f0'
      }, {
        value: 'owner',
        name: '域名持有者',
        color: '#0f0'
      }, {
        value: 'regist_time',
        name: '注册时间',
        color: '#0f0'
      }],


      listLoading: false,
      initSwiper: this.initSwiper,
      style: {
        labelListWidth: 0
      },

      rwidth: 0,
      // rheight: 0,

      data: void 0,
      images: [],
      activeSelectedLabel: '',

      page: 1,
      pageSize: 10,
      imagesLoading: false,

      activeIndex: 0,
      imageSwitching: false,

      colors: {},

      disableCreateLabel: false,
      newLabel: '',

      open: false,
      labelAddVisible: false,
      labelValue: '',
      selectedRow: {},
      // inputSelectShow: false,

      tools: props.tools ? [...props.tools] : [...initialTool],
      activeTypes: [],
      disabledTypes: [toolbarTypes.UNDO, toolbarTypes.REDO, toolbarTypes.CLEAR, toolbarTypes.SAVE],
      customContextVisible: false,
    }
    this.ctnWrapperRef = createRef()
    this.ctnRef = createRef()
    this.formRef = createRef()
  }
  setFormRef = (dom) => {
    if (!dom) {
      return
    }
    this.form = dom
  }
  setFormRefLabelAdd = (dom) => {
    if (!dom) {
      return
    }
    this.formRefLabelAdd = dom
  }
  componentDidMount() {
    this.ctnWrapper = this.ctnWrapperRef.current
    this.ctner = this.ctnRef.current
    this.form = this.formRef.current
    setTimeout(() => {
      const s = this.recalcuStyle()
      this.setState({
        style: s,
        rwidth: s.imageLabelWidth,
        // rheight: s.imageLabelHeight,
      })
    }, 10)

    window.addEventListener('resize', this.onWindowResize)
  }
  componentWillUnmount() {
    this.ctnWrapperRef = null
    this.ctnRef = null
    this.formRef = null
    this.form = null
    this.swiper = null
    this.canvas = null
    window.removeEventListener('resize', this.onWindowResize)
  }
  onWindowResize = () => {
    // 暂去除延时计算
    const s = this.recalcuStyle()
    this.setState({
      style: s,
      rwidth: s.imageLabelWidth,
      // rheight: s.imageLabelHeight,
    })
    if (this.ctner) {
      // this.setElementPostion(this.ctner)
      this.loadLabelDataByImageSrc(this.state.activeIndex)
    }
  }
  lc = (dom) => {
    if (!dom) return
    this.lcDom = dom
  }
  recalcuStyle = () => {
    const { projectType } = this.props
    // const container = document.getElementById('label-container')
    const container = this.lcDom
    if (!container) return
    const labelContainerWitdh = container.clientWidth - 20
    const labelListWidth = 0
    let imageLabelListWidth = 400
    if (projectType === 'OCR_VIEW') {
      imageLabelListWidth = -20
    } else if (projectType === 'OCR_REFER') {
    } else if (projectType === 'OCR_REFER_VIEW') {
      // imageLabelListWidth = -20
    } else if (projectType === 'OCR_CHECK') {
      // imageLabelListWidth = -20
    }
    const imageContainerWitdh = parseInt(labelContainerWitdh - labelListWidth)
    const imageLabelWidth = parseInt(imageContainerWitdh - imageLabelListWidth)
    const thumbsHeight = imageContainerWitdh / defaultPageSize * 0.75
    document.querySelector('.gallery-thumbs') && (document.querySelector('.gallery-thumbs').style.height = thumbsHeight + 'px')
    // document.querySelector('.gallery-top') && (document.querySelector('.gallery-top').style.height = (imageLabelWidth < 550 ? imageContainerWitdh : imageContainerWitdh - imageLabelListWidth) * 0.75 + 'px')
    return {
      labelListWidth,
      imageContainerWitdh,
      imageLabelWidth: imageLabelWidth < 400 ? imageLabelWidth + 400 : imageLabelWidth,
      imageLabelHeight: container.clientHeight,
      thumbsHeight
    }
  }
  setElementPostion = (elem) => {
    // 需要必要的css预设
    const h = elem.clientHeight
    const ph = elem.parentElement.clientHeight
    if (h > ph) {
      elem.style.top = '0px'
      elem.style.transform = 'translateY(0)'
    } else {
      elem.style.top = '50%'
      elem.style.transform = `translateY(${-h / 2}px)`
    }
  }
  initSwiper = async (images) => {
    const that = this
    const { projectType } = this.props
    const ds = images.map(x => ({ ...x, image_path: x.file_url }))
    // that.state.dataOrigin
    // .slice((that.state.page - 1) * that.state.pageSize, that.state.page * that.state.pageSize)
    if (!ds.length) {
      return
    }
    images = await Promise.all(ds.map(async (d, index) => {
      return {
        ...d,
        index: index,
        // image_path: await that.props.getFileUrl({ Key: d.Key }), // 为原图添加支持
      }
    }))
    // console.log('images: ', images);
    that.setState({
      // images: [...that.state.images, ...images],
      images,
      page: that.state.page + 1,
      listLoading: false,
      imagesLoading: false
    })
    that.swiper = new Swiper(`.swiper-${projectType}`, {
      virtual: {
        slides: images.map(x => `<div` +
          ` style="width: 100%; height: 100%; background-image: url(${x.image_path}${x.image_path.includes('?') ? '&' : '?'}x-oss-process=image/auto-orient,1/rounded-corners,r_1/resize,m_pad,w_140,h_198); background-repeat: no-repeat; background-position: center; background-size: contain"` +
          `>` +
          `</div>`)
      },
      slidesPerView: defaultPageSize,
      spaceBetween: 10,
      centeredSlides: true,
      lazy: {
        loadPrevNext: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        init: function () {
          const s = that.recalcuStyle()
          that.setState({
            style: s,
            rwidth: s.imageLabelWidth,
            imageSwitching: true
          }, () => {
            setTimeout(() => {
              this.update()
              if (projectType === 'OCR') {
                that.loadAllLabelDatas(() => {
                  that.loadLabelDataByImageSrc(this.activeIndex)
                })
              } else if (projectType === 'OCR_REFER') {
                that.loadLabelDataByImageSrc(this.activeIndex)
              } else if (projectType === 'OCR_VIEW') {
                that.loadLabelDataByImageSrc(this.activeIndex)
              } else if (projectType === 'OCR_CHECK') {
                that.loadAllLabelDatas(() => {
                  that.loadLabelDataByImageSrc(this.activeIndex)
                })
              } else {
                that.loadLabelDataByImageSrc(this.activeIndex)
              }
            }, 50)
          })
        },
        slideChange: function () {
          clearTimeout(that.timer)
          that.timer = setTimeout(async () => {
            if (that.state.activeTypes.includes(toolbarTypes.SHAPE_EDIT)) {
              that.handleAutoSave()
            }
            that.canvas?.clearLabel()
            that.setState({ activeIndex: this.activeIndex, imageSwitching: true }, () => {
              setTimeout(() => {
                const s = that.recalcuStyle()
                that.setState({
                  style: s,
                  rwidth: s.imageLabelWidth
                }, () => {
                  that.loadLabelDataByImageSrc(this.activeIndex)
                })
              }, 50)
            })
          }, 100)
        },
        click: function () {
          if (typeof this.clickedIndex !== 'undefined') {
            this.slideTo(this.clickedIndex)
          }
        }
      }
    })
  }
  getSlide = url => {
    return `<div` +
      ` style="width: 100%; height: 100%; background-image: url(${url}); background-repeat: no-repeat; background-position: center; background-size: contain"` +
      `>` +
      `</div>`
  }
  loadAllLabelDatas = (callback) => {
    let quene = Array(this.state.images.length).fill(0).map((x, y) => y)
    let resultImages = this.state.images
    const queneRun = (quene, result) => {
      if (!quene.length) {
        return this.setState({
          images: result
        }, () => {
          callback?.()
        })
      }
      this.loadLabelDataOnly(result, quene.shift(), (result) => {
        queneRun(quene, result)
      })
    }
    queneRun(quene, resultImages)
  }
  loadLabelDataOnly = async (resultImages, index, cb) => {
    const { images } = this.state
    if (typeof index === 'undefined' || !images.length) return cb()
    const image = images[index]
    if (!image) return cb(resultImages)
    let imageLabelData = image.labelData
    if (!imageLabelData) {
      if (image.fields?.length) {
        const data = this.props.getSTDDataByProjectType(image)
        const shapes = data.data.map((sp, spIdx) => {
          // const label = this.state.labels.find(l => l.labelName === sp.label) || {}
          const label = { color: '#0f0' }
          return {
            closed: true,
            shape: sp.shape,
            shapeId: spIdx,
            zIndex: spIdx,
            labelName: sp.labelName,
            color: label.color,
            points: sp.points && sp.points.length ? [...sp.points.map((pt, ptIdx) => {
              return {
                ...pt,
                pointRadius: 4,
                shape: 'dot',
                pointId: `${spIdx}.${ptIdx}`,
                zIndex: Number(`${spIdx}.${ptIdx}`),
                color: label.color,
                xy: pt.xy
              }
            })
            ] : [],
            pageNumber: sp.pageNumber,
            nth: sp.nth
          }
        })
        // 标注信息的可能的所有字段 包含注释
        imageLabelData = {
          // image: {
          //   Key: image.file_url,
          //   width: imageMeta.width,
          //   height: imageMeta.height,
          //   imageData: imageMeta.imageData
          // },
          shapes: shapes,
          labels: data.labels.map(x => ({ ...x, ...shapes.find(xx => xx.labelName === x.labelName) }))
        }
        cb(resultImages.map((im, idx) => idx === index ? { ...im, labelData: imageLabelData } : im))
      } else {
        imageLabelData = {
          shapes: [],
          labels: []
        }
        cb(resultImages.map((im, idx) => idx === index ? { ...im, labelData: imageLabelData } : im))
      }
    } else {
      cb(resultImages)
    }
  }
  // 加载标注文件
  loadLabelDataByImageSrc = async (index, newSrc) => {
    const images = this.state.images
    // console.log('images: ', images);
    if (typeof index === 'undefined' || !images.length) return

    const ctner = this.ctner
    const image = images[index]
    if (!image) return
    let imageLabelData = image.labelData

    if (!imageLabelData) {
      // const [err, imageLabelFile] = await this.props.getFileUrl({ Key: this.getImageAnnotationName(image.Key) }, 'requireData')
      const imageSrc = image.file_url
      const imageMeta = await utils.loadImage(imageSrc)
      if (!this.canvas) {
        this.canvas = new Canvas(ctner, {
          properties: this.props.getPropertyConfigByProjectType()
        }, this.receiveMessage)
      }

      // let [err, imageLabelFile] = await this.props.loadTextFile(labelFileSrc)
      if (image.fields?.length) {
        const data = this.props.getSTDDataByProjectType(image)
        const shapes = data.data.map((sp, spIdx) => {
          // const label = this.state.labels.find(l => l.labelName === sp.label) || {}
          const label = { color: '#0f0' }
          return {
            closed: true,
            shape: sp.shape,
            shapeId: spIdx,
            zIndex: spIdx,
            labelName: sp.labelName,
            color: label.color,
            points: sp.points && sp.points.length ? [...sp.points.map((pt, ptIdx) => {
              return {
                ...pt,
                pointRadius: 4,
                shape: 'dot',
                pointId: `${spIdx}.${ptIdx}`,
                zIndex: Number(`${spIdx}.${ptIdx}`),
                color: label.color,
                xy: pt.xy
              }
            })
            ] : []
          }
        })
        imageLabelData = {
          image: {
            Key: image.file_url,
            width: imageMeta.width,
            height: imageMeta.height,
            imageData: imageMeta.imageData
          },
          shapes: shapes,
          labels: data.labels.map(x => ({ ...x, ...shapes.find(xx => xx.labelName === x.labelName) }))
        }
        this.setState({
          images: images.map((im, idx) => idx === index ? { ...im, labelData: imageLabelData } : im),
          imageSwitching: false
        })
        this.canvas?.init(imageMeta)
        this.canvas?.updateLabel({
          ...imageLabelData,
          // shapes: imageLabelData.shapes.filter(sp => !sp.hide)
        })
      } else {
        imageLabelData = {
          image: {
            Key: image.file_url,
            width: imageMeta.width,
            height: imageMeta.height,
            imageData: imageMeta.imageData
          },
          shapes: [],
          labels: []
        }
        this.setState({
          images: images.map((im, idx) => idx === index ? {
            ...im,
            labelData: imageLabelData
          } : im),
          imageSwitching: false
        })
        this.canvas?.init(imageMeta)
        this.canvas?.updateLabel({
          ...imageLabelData,
          shapes: imageLabelData.shapes.filter(sp => !sp.hide)
        })
      }
      this.toolItemInit(this.props.projectType)
      return
    }
    if (imageLabelData && !imageLabelData.image) {
      // const [err, imageLabelFile] = await this.props.getFileUrl({ Key: this.getImageAnnotationName(image.Key) }, 'requireData')
      const imageSrc = image.file_url
      const imageMeta = await utils.loadImage(imageSrc)
      if (!this.canvas) {
        this.canvas = new Canvas(ctner, {
          properties: this.props.getPropertyConfigByProjectType()
        }, this.receiveMessage)
      }

      imageLabelData = {
        ...imageLabelData,
        image: {
          Key: image.file_url,
          width: imageMeta.width,
          height: imageMeta.height,
          imageData: imageMeta.imageData
        },
      }
      this.setState({
        images: images.map((im, idx) => idx === index ? {
          ...im,
          labelData: imageLabelData
        } : im),
        imageSwitching: false
      })
      this.canvas?.init(imageMeta)
      this.canvas?.updateLabel({
        ...imageLabelData,
        shapes: imageLabelData.shapes.filter(sp => !sp.hide)
      })
      this.toolItemInit(this.props.projectType)
      return
    }
    if (imageLabelData.image && !imageLabelData.image.imageData) {
      const imageSrc = image.file_url
      const imageMeta = await utils.loadImage(imageSrc)

      imageLabelData = {
        ...imageLabelData,
        image: {
          ...imageLabelData.image,
          width: imageMeta.width,
          height: imageMeta.height,
          imageData: imageMeta.imageData
        }
      }
      if (!this.canvas) {
        this.canvas = new Canvas(ctner, {
          properties: this.props.getPropertyConfigByProjectType()
        }, this.receiveMessage)
      }
      this.setState({
        images: images.map((im, idx) => idx === index ? { ...im, labelData: imageLabelData } : im),
        tools: this.state.tools.map(it => ({ ...it, disabled: it.type === toolbarTypes.SHAPE_EDIT && (imageLabelData.shapes || []).length ? false : it.disabled })),
        imageSwitching: false
      })
      this.canvas?.init(imageMeta)
      this.canvas?.updateLabel({
        ...imageLabelData,
        shapes: imageLabelData.shapes.filter(sp => !sp.hide)
      })
      this.toolItemInit(this.props.projectType)
      return
    }
    if (newSrc) {
      const imageSrc = image.newSrc
      const imageMeta = await utils.loadImage(imageSrc)
      imageLabelData = {
        ...imageLabelData,
        image: {
          Key: image.file_url + `?_=${Date.now()}`,
          width: imageMeta.width,
          height: imageMeta.height,
          imageData: imageMeta.imageData
        },
      }
      this.setState({
        images: images.map((im, idx) => idx === index ? {
          ...im,
          labelData: imageLabelData
        } : im),
        imageSwitching: false
      })
      this.canvas?.init(imageMeta)
      this.canvas?.updateLabel({
        ...imageLabelData,
        shapes: imageLabelData.shapes.filter(sp => !sp.hide)
      })
      return
    }
    if (!this.canvas) {
      this.canvas = new Canvas(ctner, {
        properties: this.props.getPropertyConfigByProjectType()
      }, this.receiveMessage)
    }
    this.setState({
      imageSwitching: false
    })
    this.canvas?.init(imageLabelData.image)
    this.canvas?.updateLabel({
      ...imageLabelData,
      shapes: imageLabelData.shapes.filter(sp => !sp.hide)
    })
    this.toolItemInit(this.props.projectType)
  }
  toolItemInit = (projectType) => {
    if (projectType === 'OCR') {
      this.onToolItemClick({ type: 'rectangle' })
    } else if (projectType === 'OCR_REFER') {
      this.onToolItemClick({ type: 'rectangle' })
    } else if (projectType === 'OCR_VIEW') {
      this.onToolItemClick({ type: 'shape-move-whole' })
    } else if (projectType === 'OCR_REFER_VIEW') {
      this.onToolItemClick({ type: 'shape-move-whole' })
    } else if (projectType === 'OCR_CHECK') {
      this.onToolItemClick({ type: 'rectangle' })
    }
  }
  handleAutoSave = () => {
    this.handleSave(() => {
      this.updateImageLabelData()
    })
  }

  // 单个标注文件
  getImageAnnotationName = (imageSrc, bucketName, prefix) => {
    const url = imageSrc.includes('?') ? imageSrc.slice(0, imageSrc.indexOf('?')) : imageSrc
    const originalKey = url.split(bucketName).slice(-1)[0].split(prefix).slice(-1)[0]
    const ext = originalKey.split('.').slice(-1).toString()
    const newKey = originalKey.replace(new RegExp(`${ext}$`), 'json')
    return newKey
  }

  handleClear = () => {
    // 只清空未画完的形状
    this.canvas?.clearLabel()
  }
  activate = (shape) => {
    // const overflow = this.state.labels.length + 1 >= commonColors.length
    // let color = overflow ? this.randomColor() : this.pickColor()
    // while (this.state.labels.find(l => l.color === color)) {
    //   color = overflow ? this.randomColor() : this.pickColor()
    // }
    const color = GREEN
    if (shape === toolbarTypes.RECTANGLE) return this.canvas?.startRectangle(shape, color)
    if (shape === toolbarTypes.POLYGON) return this.canvas?.startPolygon(shape, color)
  }
  onToolItemClick = (tool) => {
    const { intl: { formatMessage } } = this.props
    if (!this.state.images.length) {
      return
    }
    switch (tool.type) {
      case toolbarTypes.RECTANGLE:
        this.activateRectangle(tool)
        break;
      case toolbarTypes.POLYGON:
        this.activatePolygon(tool)
        break;
      case toolbarTypes.SHAPE_EDIT:
        this.shapeEdit(tool)
        break;
      case toolbarTypes.SHAPE_MOVE:
        this.shapeMove(tool)
        break;
      case toolbarTypes.SHAPE_MOVE_WHOLE:
        this.shapeMoveWhole(tool)
        break;
      case toolbarTypes.BACK_TO_CENTER:
        this.backToCenter(tool)
        break;
      case toolbarTypes.PERSPECTIVE_TRANSFORMATION:
        this.canvas?.pstf()
        break;
      case toolbarTypes.UNDO:
        this.canvas?.undo()
        break;
      case toolbarTypes.REDO:
        this.canvas?.redo()
        break;
      case toolbarTypes.ZOOM_IN:
        this.canvas?.zoomIn()
        break;
      case toolbarTypes.ZOOM_OUT:
        this.canvas?.zoomOut()
        break;
      case toolbarTypes.CLEAR:
        this.handleClear()
        break;
      case toolbarTypes.SAVE:
        this.handleSave()
        break;
      case toolbarTypes.FULL_CLIENT:
        this.toggleFullScreen(tool)
        break;
      default:
        break;
    }
  }
  activatePolygon = (tool) => {
    if (this.state.activeTypes.includes(toolbarTypes.SHAPE_EDIT)) {
      this.handleAutoSave()
    }
    this.activate(toolbarTypes.POLYGON)
    this.setState({
      disabledTypes: [],
      activeTypes: [tool.type]
    })
  }
  activateRectangle = (tool) => {
    if (this.state.activeTypes.includes(toolbarTypes.SHAPE_EDIT)) {
      this.handleAutoSave()
    }
    this.activate(toolbarTypes.RECTANGLE)
    this.setState({
      disabledTypes: [],
      activeTypes: [tool.type]
    })
  }
  // 旧版编辑
  shapeEdit = (tool) => {
    this.canvas?.shapeEdit()
    this.setState({
      disabledTypes: [toolbarTypes.UNDO, toolbarTypes.REDO, toolbarTypes.CLEAR],
      activeTypes: [tool.type]
    })
  }
  // 新版移动 可编辑标注
  shapeMove = (tool) => {
    this.canvas?.shapeMove()
    this.setState({
      disabledTypes: [toolbarTypes.UNDO, toolbarTypes.REDO, toolbarTypes.CLEAR],
      activeTypes: [tool.type]
    })
  }
  // 整体平移
  shapeMoveWhole = (tool) => {
    this.canvas?.shapeMoveWhole()
    this.setState({
      disabledTypes: [],
      activeTypes: [tool.type]
    })
  }
  // 回中
  backToCenter = (tool) => {
    this.loadLabelDataByImageSrc(this.state.activeIndex)
    // this.setState({
    //   disabledTypes: [],
    //   activeTypes: [tool.type]
    // })
  }
  // 完成标注
  handleSave = (cb) => {
    const { intl: { formatMessage } } = this.props
    if (cb) {
      return cb()
    }
    this.updateImageLabelData()
      .then(res => {
        return message.success(formatMessage({ id: 'done_success' }))
      }).catch((err) => {
        return message.error(err.response && err.response.data && err.response.data.message)
      })
  }
  toggleFullScreen = (tool) => {
    if (!this.isFullSreen) {
      this.isFullSreen = true
      return this.fullScreen(document.getElementById('data-label'))
    }
    this.isFullSreen = false
    return this.exitFullscreen()
  }
  autoGetNameNumber = (arr, prefix) => {
    const numArr = arr.map(x => x.key.match(new RegExp(`^${prefix || '字段名'}(\\d+)$`))?.[1])
    const numIndex = Math.max.apply(null, numArr.filter(Boolean))
    return numIndex < arr.length ? arr.length + 1 : numIndex + 1
  }
  autoGetNameNth = (arr) => {
    return Math.max.apply(null, arr.map(x => x.nth)) + 1
  }
  // 抛出消息类型
  receiveMessage = ({ type, data }) => {
    const { projectType } = this.props
    switch (type) {
      case messageTypes.IF_SHAPE_COMPLETE:
        this.isEdit = false
        // if (this.isFullSreen) {
        //   this.fullScreen(document.getElementById('label-modal'))
        // }
        // 手动输入流程
        if (this.props.manual) {
          this.setState({ labelAddVisible: true }, () => {
            const d = document.getElementById('contentWrap')
            d && (d.style.overflow = 'hidden');
            // window.addEventListener('click', this.addSelectListener)
            this.form.setFieldsValue({ label: data.labelName, color: data.color })
          })
          return
        }
        // 自动生成字段名
        let { images = [], activeIndex } = this.state
        let imageLabels = []
        let newData = {}
        // let imageLabels = images[activeIndex]?.labelData?.labels ?? []
        if (projectType === 'OCR') {
          imageLabels = this.loadAllLabelData(images)
          newData = {
            // labelName: values.labelName,
            // key: values.key,
            labelName: uuidv4(),
            key: imageLabels.length ? `字段名${this.autoGetNameNumber(imageLabels, '字段名')}` : '字段名1',
            description: '',
            nth: imageLabels.length ? this.autoGetNameNth(imageLabels) : 0,
            pageNumber: images[activeIndex].pageNumber
          };
        } else if (projectType === 'OCR_REFER') {
          imageLabels = images[activeIndex]?.labelData?.labels ?? []
          newData = {
            // labelName: values.labelName,
            // key: values.key,
            labelName: imageLabels.length ? `参照字段${this.autoGetNameNumber(imageLabels, '参照字段')}` : '参照字段1',
            key: imageLabels.length ? `参照字段${this.autoGetNameNumber(imageLabels, '参照字段')}` : '参照字段1',
            description: ''
          };
        }

        const newDataSource = [...imageLabels, newData]
        this.handleTableChange(newDataSource);
        setTimeout(() => {
          const color = GREEN
          const newColor = GREEN
          this.canvas?.rectangleLabelDone({ labelName: newData.labelName, color: color, newColor: newColor })
        }, 50)
        break;
      case messageTypes.CREATE_RECTANGLE:
        this.handleCreateLabel(data)
        break;
      case messageTypes.DISTANCE_TOO_SMALL:
        message.warn(data.message ?? '框选范围太小，请重试')
        break;
      case messageTypes.PRESS_MOUSE_TO_SELECT:
        message.warn(data.message ?? '请按住鼠标框选')
        break;
      case messageTypes.NO_MORE_ZOOM_IN:
        message.warn(data.message ?? messageTypes.NO_MORE_ZOOM_IN)
        break;
      case messageTypes.NO_MORE_ZOOM_OUT:
        message.warn(data.message ?? messageTypes.NO_MORE_ZOOM_OUT)
        break;
      case messageTypes.CREATE_POLYGON:
        this.handleCreateLabel(data)
        break;
      case messageTypes.CONTEXT_CLICK:
        this.__shape = data.shape
        this._handleContextMenu(data.event)
        break;
      case messageTypes.CONTEXT_NO_CLICK:
        this._handleClick(data.event)
        break;
      case messageTypes.CONTEXT_SCROLL:
        this._handleScroll(data.event)
        break;
      case messageTypes.SYNC_CANVAS_TO_OUTSIDE:
        this.syncCanvasDataToState(data)
        break;
      case messageTypes.PSTF_DATA:
        this.handlePSTFData(data)
        break;

      case messageTypes.ACTIVATE_SHAPE:
        if (this.state.activeSelectedLabel !== data.labelName) {
          this.setState({ activeSelectedLabel: data.labelName })
          document.getElementById(`label-${data.labelName}`)?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
        break;
      default:
        break;
    }
  }
  addSelectListener = (evt) => {
    if (this.state.inputSelectShow && evt.target !== document.querySelector('.input-select-input>input')) {
      this.setState({ inputSelectShow: false })
    }
  }
  // 单个图片的缓存标注
  handleCreateLabel = (imageLabel) => {
    const { intl: { formatMessage }, projectType } = this.props;
    const { images, activeIndex } = this.state;
    const image = images[activeIndex]
    // window.removeEventListener('click', this.addSelectListener)
    if (projectType === 'OCR') {
      this.setState({
        images: images.map((img, idx) => {
          return idx === activeIndex ? {
            ...img,
            labelData: {
              ...img.labelData,
              shapes: img.labelData?.shapes?.find(x => x.labelName === imageLabel.labelName) ? img.labelData.shapes.map(x => x.labelName === imageLabel.labelName ? imageLabel : x) : [...(img.labelData?.shapes || []), imageLabel]
            }
          } : {
            ...img,
            labelData: {
              ...img.labelData,
              shapes: img.labelData?.shapes?.filter(x => x.labelName !== imageLabel.labelName)
            }
          }
        }),
      }, () => {
        this.handleRerecognize(imageLabel)
      })
    } else if (projectType === 'OCR_REFER') {
      this.setState({
        images: images.map((img, idx) => {
          return idx === activeIndex ? {
            ...img,
            labelData: {
              ...img.labelData,
              shapes: img.labelData.shapes?.find(x => x.labelName === imageLabel.labelName) ? img.labelData.shapes.map(x => x.labelName === imageLabel.labelName ? imageLabel : x) : [...(img.labelData.shapes || []), imageLabel]
            }
          } : img
        }),
      }, () => {
        this.handleRerecognize(imageLabel)
      })
    } if (projectType === 'OCR_CHECK') {
      this.setState({
        images: images.map((img, idx) => {
          return idx === activeIndex ? {
            ...img,
            labelData: {
              ...img.labelData,
              shapes: img.labelData.shapes?.find(x => x.labelName === imageLabel.labelName) ? img.labelData.shapes.map(x => x.labelName === imageLabel.labelName ? imageLabel : x) : [...(img.labelData.shapes || []), imageLabel]
            }
          } : {
            ...img,
            labelData: {
              ...img.labelData,
              shapes: img.labelData.shapes?.filter(x => x.labelName !== imageLabel.labelName)
            }
          }
        }),
      }, () => {
        this.handleRerecognize(imageLabel)
      })
    }

  }
  syncCanvasDataToState = (data) => {
    // 延时更新
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.setState({
        images: this.state.images.map((img, idx) => {
          return idx === this.state.activeIndex ? {
            ...img,
            labelData: {
              ...img.labelData,
              labels: img.labelData.labels.map(x => ({ ...x, ...data.shapes?.find(xs => xs.labelName === x.labelName) })),
              ...data,
            },
          } : img
        }),
      }, this.updateImageLabelData)
    }, 100);
  }

  addImageLabelOk = () => {
    let { images = [], activeIndex } = this.state
    const { intl: { formatMessage } } = this.props;
    this.form.validateFields(['labelName']).then(values => {
      this.setState({ labelAddVisible: false })
      // const color = this.state.labels.find(l => l.value === values.labelName)?.color ?? '0f0'
      // // console.log('color: ', color);
      // const overflow = this.state.labels.length + 1 >= commonColors.length
      // let newColor = overflow ? this.randomColor() : this.pickColor()
      // while (this.state.labels.find(l => l.color === newColor)) {
      //   newColor = overflow ? this.randomColor() : this.pickColor()
      // }
      // this.canvas?.labelDone({ labelName: values.labelName, color: color, newColor: newColor })
      const color = GREEN
      const newColor = GREEN
      this.canvas?.rectangleLabelDone({ labelName: values.labelName, color: color, newColor: newColor })
      this.form.resetFields()
    })
  }
  addImageLabelCancel = () => {
    this.setState({ labelAddVisible: false })
    this.canvas?.undo()
    if (!this.isEdit && this.state.activeTypes[0] && this.canvas[`${this.state.activeTypes[0]}LabelDoneCancel`]) {
      this.canvas[`${this.state.activeTypes[0]}LabelDoneCancel`]()
    }
  }

  // 先只删除当前图片的标签, 不对所有标签进行处理(即去掉所有标签里的选中图片记录)
  handleDeleteImageLabel = (row) => {
    const { intl: { formatMessage } } = this.props;
    this.isEdit = false
    const { images, activeIndex } = this.state
    Modal.confirm({
      title: '提示',
      content: formatMessage({ id: 'confirm_delete' }),
      okText: formatMessage({ id: 'ok' }),
      cancelText: formatMessage({ id: 'cancel' }),
      onOk: () => {
        this.handleSave(() => {
          const funcs = {}
          funcs['OCR'] = () => {
            this.setState({
              images: images.map((i, idx) => {
                return activeIndex === idx ? {
                  ...i,
                  labelData: i.labelData ? {
                    ...i.labelData,
                    labels: i.labelData.labels.filter(sp => sp.labelName !== row.labelName),
                    shapes: i.labelData.shapes ? i.labelData.shapes.filter(sp => sp.shapeId !== row.shapeId) : []
                  } : {
                    labels: []
                  }
                } : i
              })
            }, this.updateImageLabelData)
          }
          funcs['OCR_REFER'] = () => {
            this.setState({
              images: images.map((i, idx) => idx === activeIndex ? ({
                ...i,
                labelData: i.labelData ? {
                  ...i.labelData,
                  labels: i.labelData.labels.filter(sp => sp.labelName !== row.labelName),
                  shapes: i.labelData.shapes.filter(sp => sp.shapeId !== row.shapeId)
                } : {
                  labels: []
                }
              }) : i)
            }, this.updateImageLabelData)
          }
          funcs[this.props.projectType]?.()
        })
      },
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' }
    })
  }
  handletoggleHideLabelItem = (row) => {
    this.setState({
      images: this.state.images.map((img, idx) => {
        return this.state.activeIndex === idx ? {
          ...img,
          labelData: {
            ...img.labelData,
            shapes: img.labelData.shapes.map(sp => ({
              ...sp,
              hide: sp.shapeId === row.shapeId ? !sp.hide : sp.hide
            }))
          }
        } : img
      }),
    }, () => {
      const imageLabelData = this.state.images[this.state.activeIndex].labelData
      this.canvas?.updateLabel({
        ...imageLabelData,
        shapes: imageLabelData.shapes.filter(sp => !sp.hide)
      })
    })
  }
  handleLabelClick = (row) => {
    const { projectType } = this.props
    if (projectType === 'OCR' || projectType === 'OCR_CHECK') {
      if (typeof row.pageNumber === 'undefined' || typeof row.description === 'undefined') {
        return
      }
      this.swiper.slideTo(row.pageNumber)
      clearTimeout(this._t)
      this._t = setTimeout(() => {
        this.setState({
          activeIndex: row.pageNumber,
          images: this.state.images.map((img, idx) => {
            return row.pageNumber === idx ? {
              ...img,
              labelData: img.labelData && {
                ...img.labelData,
                shapes: img.labelData?.shapes?.map(sp => ({
                  ...sp,
                  alpha: sp.shapeId === row.shapeId ? 0.7 : void 0,
                  lineWidth: sp.shapeId === row.shapeId ? 3 : void 0
                })) ?? []
              }
            } : img
          }),
        }, () => {
          const imageLabelData = this.state.images[row.pageNumber]?.labelData
          if (!imageLabelData) { return }
          this.canvas && this.canvas?.updateLabel({
            ...imageLabelData,
            shapes: imageLabelData?.shapes.filter(sp => !sp.hide) ?? []
          })
        })
      }, 350)
    } else if (projectType === 'OCR_REFER') {
      this.setState({
        images: this.state.images.map((img, idx) => {
          return this.state.activeIndex === idx ? {
            ...img,
            labelData: img.labelData && {
              ...img.labelData,
              shapes: img.labelData.shapes.map(sp => ({
                ...sp,
                alpha: sp.shapeId === row.shapeId ? 0.7 : void 0,
                lineWidth: sp.shapeId === row.shapeId ? 3 : void 0
              }))
            }
          } : img
        }),
      }, () => {
        const imageLabelData = this.state.images[this.state.activeIndex].labelData
        if (!imageLabelData) { return }
        this.canvas?.updateLabel({
          ...imageLabelData,
          shapes: imageLabelData.shapes.filter(sp => !sp.hide)
        })
      })
    }
  }
  handleLabelTop = (row, showMessage) => {
    const { intl: { formatMessage } } = this.props;
    this.setState({
      images: this.state.images.map((img, idx, arr) => {
        return this.state.activeIndex === idx ? {
          ...img,
          labelData: {
            ...img.labelData,
            shapes: img.labelData?.shapes.map((sp, idx, lds) => ({
              ...sp,
              alpha: sp.shapeId === row.shapeId ? 0.7 : void 0,
              lineWidth: sp.shapeId === row.shapeId ? 3 : void 0,
              zIndex: sp.shapeId === row.shapeId ? Math.max.apply(null, lds.map(x => x.zIndex)) + 1 : sp.shapeId,
              points: sp.points && sp.points.length ? [...sp.points.map((pt, ptIdx) => {
                return {
                  ...pt,
                  zIndex: Number(`${Math.max.apply(null, lds.map(x => x.zIndex)) + 1}.${ptIdx}`),
                }
              })] : []
            })).sort((a, b) => a.zIndex - b.zIndex)
          }
        } : img
      }),
    }, () => {
      const imageLabelData = this.state.images[this.state.activeIndex].labelData
      this.canvas?.updateLabel({
        ...imageLabelData,
        shapes: imageLabelData?.shapes?.filter(sp => !sp.hide) ?? []
      })

      showMessage && message.info(`置顶成功`)
    })
  }
  handleLabelBottom = (row, showMessage) => {
    const { intl: { formatMessage } } = this.props;
    this.setState({
      images: this.state.images.map((img, idx, arr) => {
        return this.state.activeIndex === idx ? {
          ...img,
          labelData: {
            ...img.labelData,
            shapes: img.labelData?.shapes.map((sp, idx, lds) => ({
              ...sp,
              alpha: sp.shapeId === row.shapeId ? 0.7 : void 0,
              lineWidth: sp.shapeId === row.shapeId ? 3 : void 0,
              zIndex: sp.shapeId === row.shapeId ? Math.min.apply(null, lds.map(x => x.zIndex)) - 1 : sp.shapeId,
              points: sp.points && sp.points.length ? [...sp.points.map((pt, ptIdx) => {
                return {
                  ...pt,
                  zIndex: Number(`${Math.min.apply(null, lds.map(x => x.zIndex)) - 1}.${ptIdx}`),
                }
              })] : []
            })).sort((a, b) => a.zIndex - b.zIndex)
          }
        } : img
      }),
    }, () => {
      const imageLabelData = this.state.images[this.state.activeIndex].labelData
      this.canvas?.updateLabel({
        ...imageLabelData,
        shapes: imageLabelData?.shapes?.filter(sp => !sp.hide) ?? []
      })

      showMessage && message.info(`置底成功`)
    })
  }
  handleLabelLeave = () => {
    clearTimeout(this._t)
    if (!this.state.images.length || !this.canvas) return
    this.setState({
      images: this.state.images.map((img, idx) => {
        return this.state.activeIndex === idx ? {
          ...img,
          labelData: img.labelData ? {
            ...img.labelData,
            shapes: img.labelData.shapes.map(sp => ({
              ...sp,
              alpha: void 0,
              lineWidth: void 0
            }))
          } : void 0
        } : img
      }),
    }, () => {
      const imageLabelData = this.state.images[this.state.activeIndex].labelData
      if (!imageLabelData) { return }
      this.canvas?.updateLabel({
        ...imageLabelData,
        shapes: (imageLabelData.shapes || []).filter(sp => !sp.hide)
      })
    })
  }
  getOcrRecognizedText = async (image, data) => {
    let position = this.props.getLabelDataSingleByProjectType(data)
    let result = await this.props.getOcrRecognizedTextSingle(image.file_id, {
      file_url: image.file_url,
      positions: [position.join('.')]
    })
    return result
  }
  handlePSTFData = async (data) => {
    let result = await this.props.getPSTFData(data.data)
    const imgSrc = `data:image/png;base64,${result.image.replace(/_/g, '/').replace(/-/g, '+')}`
    this.setState({
      images: this.state.images.map((img, idx) => {
        return idx === this.state.activeIndex ? {
          ...img,
          newSrc: imgSrc
        } : img
      })
    }, () => {
      this.loadLabelDataByImageSrc(this.state.activeIndex, imgSrc)
    })
    // return result
  }
  updateImageLabelData = async () => {
    const imageLabelData = this.state.images[this.state.activeIndex].labelData
    if (!imageLabelData) { return }
    this.canvas && this.canvas?.updateLabel({
      ...imageLabelData,
      shapes: (imageLabelData.shapes || []).filter(sp => !sp.hide)
    })
    this.props.onChange?.(this.props.getRequestStructureByProjectType?.(this.state.images, this.loadAllLabelData(this.state.images)))
    // return this.props.uploadJSON(this.getImageAnnotationName(this.state.images[this.state.activeIndex].Key), upld)
  }
  pickColor = () => {
    const builtinColorsLength = commonColors.length
    return commonColors[parseInt(Math.random() * builtinColorsLength)]
  }
  randomColor = () => {
    const red = parseInt(Math.random() * 256)
    const resultRed = red < 16 ? `0${red.toString(16)}` : red.toString(16)
    const green = parseInt(Math.random() * 256)
    const resultGreen = green < 16 ? `0${green.toString(16)}` : green.toString(16)
    const blue = parseInt(Math.random() * 256)
    const resultBlue = blue < 16 ? `0${blue.toString(16)}` : blue.toString(16)
    const result = `#${resultRed}${resultGreen}${resultBlue}`
    return result
  }


  fullScreen = ele => {
    ele = ele || document.documentElement
    const func =
      ele.requestFullscreen ||
      ele.mozRequestFullScreen ||
      ele.webkitRequestFullscreen ||
      ele.msRequestFullscreen;
    func.call(ele);
  };
  exitFullscreen = () => {
    const func =
      document.exitFullScreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen ||
      document.msExitFullscreen;
    func.call(document);
  };
  // 存储表格编辑的数据
  handleTableChange = (vals) => {
    console.log('vals: ', vals);
    const { images, activeIndex } = this.state
    const funcs = {}
    funcs['OCR'] = () => {
      this.setState({
        images: images.map((i, idx) => {
          return {
            ...i,
            labelData: i.labelData ? {
              ...i.labelData,
              labels: vals.filter(x => x.pageNumber === i.pageNumber),
              shapes: i.labelData.shapes ? i.labelData.shapes.filter(x => vals.find(xx => xx.labelName === x.labelName)) : []
            } : {
              labels: vals.filter(x => x.pageNumber === i.pageNumber)
            }
          }
        })
      }, this.updateImageLabelData)
    }
    funcs['OCR_REFER'] = () => {
      this.setState({
        images: images.map((i, idx) => idx === activeIndex ? ({
          ...i,
          labelData: i.labelData ? {
            ...i.labelData,
            labels: vals,
            shapes: i.labelData.shapes ? i.labelData.shapes.filter(x => vals.find(xx => xx.labelName === x.labelName)) : []
          } : {
            labels: vals
          }
        }) : i)
      }, this.updateImageLabelData)
    }
    funcs['OCR_VIEW'] = () => {
      this.setState({
        images: images.map((i, idx) => {
          return {
            ...i,
            labelData: i.labelData ? {
              ...i.labelData,
              labels: vals.filter(x => x.pageNumber === i.pageNumber),
              shapes: i.labelData.shapes ? i.labelData.shapes.filter(x => vals.find(xx => xx.labelName === x.labelName)) : []
            } : {
              labels: vals.filter(x => x.pageNumber === i.pageNumber)
            }
          }
        })
      }, this.updateImageLabelData)
    }
    funcs['OCR_CHECK'] = () => {
      this.setState({
        images: images.map((i, idx) => {
          return {
            ...i,
            labelData: i.labelData ? {
              ...i.labelData,
              labels: vals.filter(x => x.pageNumber === i.pageNumber),
              shapes: i.labelData.shapes ? i.labelData.shapes.filter(x => vals.find(xx => xx.labelName === x.labelName)) : []
            } : {
              labels: vals.filter(x => x.pageNumber === i.pageNumber)
            }
          }
        })
      }, this.updateImageLabelData)
    }
    funcs[this.props.projectType]?.()
  }
  handleRerecognize = (lb) => {
    const { projectType } = this.props
    const { images, activeIndex } = this.state;
    const image = images[activeIndex]
    this.getOcrRecognizedText(image, lb).then(({ text, text_position }) => {
      console.log('text: ', text);
      this.setState({
        images: images.map((img, idx) => {
          if (projectType === 'OCR') {
            return {
              ...img,
              labelData: {
                ...img.labelData,
                labels: img.labelData.labels.map(x => x.labelName === lb.labelName ? ({ ...x, ...img.labelData?.shapes?.find(xs => xs.labelName === x.labelName), ...lb, description: text, text_position, pageNumber: image.pageNumber }) : x)
              }
            }
          } else if (projectType === 'OCR_REFER') {
            return idx === activeIndex ? {
              ...img,
              labelData: {
                ...img.labelData,
                labels: img.labelData.labels.map(x => x.labelName === lb.labelName ? ({ ...x, ...img.labelData?.shapes?.find(xs => xs.labelName === x.labelName), description: text, text_position }) : x)
              }
            } : img
          } if (projectType === 'OCR_CHECK') {
            return {
              ...img,
              labelData: {
                ...img.labelData,
                labels: img.labelData.labels.map(x => x.labelName === lb.labelName ? ({ ...x, ...img.labelData?.shapes?.find(xs => xs.labelName === x.labelName), ...lb, description: text, text_position, pageNumber: image.pageNumber }) : x)
              }
            }
          }
        })
      }, this.updateImageLabelData)
    }).catch(error => {
      console.dir(error);
      message.error(error.response?.data?.message ?? JSON.stringify(error))
      const text = ''
      const text_position = []
      this.setState({
        images: images.map((img, idx) => {
          if (projectType === 'OCR') {
            return {
              ...img,
              labelData: {
                ...img.labelData,
                labels: img.labelData.labels.map(x => x.labelName === lb.labelName ? ({ ...x, ...img.labelData?.shapes?.find(xs => xs.labelName === x.labelName), ...lb, description: text, text_position, pageNumber: image.pageNumber }) : x)
              }
            }
          } else if (projectType === 'OCR_REFER') {
            return idx === activeIndex ? {
              ...img,
              labelData: {
                ...img.labelData,
                labels: img.labelData.labels.map(x => x.labelName === lb.labelName ? ({ ...x, ...img.labelData?.shapes?.find(xs => xs.labelName === x.labelName), description: text, text_position }) : x)
              }
            } : img
          } if (projectType === 'OCR_CHECK') {
            return {
              ...img,
              labelData: {
                ...img.labelData,
                labels: img.labelData.labels.map(x => x.labelName === lb.labelName ? ({ ...x, ...img.labelData?.shapes?.find(xs => xs.labelName === x.labelName), ...lb, description: text, text_position, pageNumber: image.pageNumber }) : x)
              }
            }
          }
        })
      }, this.updateImageLabelData)
    })
  }
  getLabelShape = (image) => {
    const data = this.props.getSTDDataByProjectType(image)
    const shapes = data.data.map((sp, spIdx) => {
      // const label = this.state.labels.find(l => l.labelName === sp.label) || {}
      const label = { color: '#0f0' }
      return {
        closed: true,
        shape: sp.shape,
        shapeId: spIdx,
        zIndex: spIdx,
        labelName: sp.labelName,
        color: label.color,
        points: sp.points && sp.points.length ? [...sp.points.map((pt, ptIdx) => {
          return {
            ...pt,
            pointRadius: 4,
            shape: 'dot',
            pointId: `${spIdx}.${ptIdx}`,
            zIndex: Number(`${spIdx}.${ptIdx}`),
            color: label.color,
            xy: pt.xy
          }
        })
        ] : [],
        nth: sp.nth,
        pageNumber: sp.pageNumber
      }
    })
    return {
      shapes: shapes,
      labels: data.labels.map(x => ({ ...x, ...shapes.find(xx => xx.labelName === x.labelName) }))
    }
  }
  loadAllLabelData = (images) => {
    const allLabels = images.reduce((pre, cur) => {
      // const { labels } = this.getLabelShape(cur)
      const labels = cur.labelData?.labels ?? []
      return [...pre, ...labels]
    }, []).sort((a, b) => a.nth - b.nth)
    return allLabels.reduce((pre, cur) => pre.find(x => x.labelName === cur.labelName) ? pre : [...pre, cur], [])
  }
  // 右键
  _handleContextMenu = (event) => {

    // this.setState({ customContextVisible:false });//当点击其他地方右键时可以根据需求来判断是否需要先关闭菜单
    event.preventDefault();

    this.setState({ customContextVisible: true }, () => {
      const clickX = event.clientX;
      const clickY = event.clientY;               //事件发生时鼠标的Y坐标
      const screenW = window.innerWidth;   //文档显示区的宽度
      const screenH = window.innerHeight;
      const rootW = this.root.offsetWidth;     //右键菜单本身元素的宽度
      const rootH = this.root.offsetHeight;

      // right为true，说明鼠标点击的位置到浏览器的右边界的宽度可以放contextmenu。

      // 否则，菜单放到左边。 // top和bottom，同理。

      const right = (screenW - clickX) > rootW;
      const left = !right;
      const top = (screenH - clickY) > rootH;
      const bottom = !top;

      if (right) {
        this.root.style.left = `${clickX + 15}px`;
      }


      if (left) {
        this.root.style.left = `${clickX - rootW - 15}px`;
      }

      if (top) {
        this.root.style.top = `${clickY + 15}px`;
      }

      if (bottom) {
        this.root.style.top = `${clickY - rootH - 15}px`;
      }
    });
  };

  _handleClick = (event) => {
    const { customContextVisible } = this.state;
    const wasOutside = !(event.target.contains === this.root);

    if (wasOutside && customContextVisible) this.setState({ customContextVisible: false, });
  };

  _handleScroll = () => {
    const { customContextVisible } = this.state;

    if (customContextVisible) this.setState({ customContextVisible: false, });
  };

  onContextItemClick = ({ key }) => {
    if (this.__shape) {
      if (key === 'top') {
        this.handleLabelTop(this.__shape, 'showMessage')
      } else if (key === 'bottom') {
        this.handleLabelBottom(this.__shape, 'showMessage')
      } else if (key === 'delete') {
        this.handleDeleteImageLabel(this.__shape)
        this.setState({ customContextVisible: false, })
      } else if (key === 'rerecognize') {
        this.handleRerecognize(this.__shape)
      }
      this.__shape = void 0
    }
    this.canvas?.onContextEnd()
  }
  onResize = (event, { element, size, handle }) => {
    console.log('size: ', size);
    this.canvas = null
    this.setState({ rwidth: size.width }, () => {
      this.loadLabelDataByImageSrc(this.state.activeIndex)
      this.swiper?.update()
    })
  };
  // renders start
  renderLableViewByProjectType = (projectType) => {
    let { images = [], activeIndex } = this.state
    // console.log('images: ', images);
    let imageLabels = images[activeIndex]?.labelData?.labels ?? []
    if (projectType === 'OCR') {
      imageLabels = this.loadAllLabelData(images)
    } else if (projectType === 'OCR_VIEW') {
      imageLabels = this.loadAllLabelData(images)
    } else if (projectType === 'OCR_REFER') {
      // 参照字段不排序
      // imageLabels = [...imageLabels].sort((a, b) => b.shapeId - a.shapeId)
    } else if (projectType === 'OCR_CHECK') {
      imageLabels = this.loadAllLabelData(images)
    }

    // imageLabels = [
    //   { labelName: 'host', key: '域名', description: 'dongyunruilian.net' },
    //   { labelName: 'owner', key: '域名 持有者', description: '东云睿连（武汉）计算技术有限公司' },
    //   { labelName: 'regist_time', key: '域名注册日期', description: '2019-12-11' },
    //   { labelName: 'expires', key: '域名到期日期', description: '2022-12-11' },
    // ]
    return <>
      {
        projectType === 'OCR' && <>
          {
            imageLabels.length ? <EditableTable
              hideEditColumn={this.props.hideEditColumn}
              hideLableAdd={(0, true)}
              valueTextEdit={this.props.valueTextEdit}
              labelTextEdit={this.props.labelTextEdit}
              alwaysEditable={this.props.alwaysEditable}
              showPrimaryFieldBtn={this.props.showPrimaryFieldBtn}
              hideDelete={this.props.hideDelete}

              dataSource={imageLabels}
              onChange={this.handleTableChange}
              activeSelectedLabel={this.state.activeSelectedLabel}
              handleItemClick={this.handleLabelTop}
              handleItemEnter={this.handleLabelClick}
              handleWrapLeave={this.handleLabelLeave}
              rerecognize={this.handleRerecognize}
            /> : <Drawer
              title="帮助信息"
              placement="right"
              closable={false}
              // onClose={onClose}
              open={true}
              getContainer={false}
              style={{
                position: 'absolute',
                width: '100%'
              }}
              contentWrapperStyle={{
                width: '100%'
              }}
            >
              <OCRHelper
                style={{ width: '100%', textAlign: 'left' }}
                colSpan={24}
              />
            </Drawer>
          }
        </>
      }
      {
        projectType === 'OCR_REFER' && <>
          {
            imageLabels.length ? <EditableTableForReferField
              hideEditColumn={this.props.hideEditColumn}
              hideLableAdd={(0, true)}
              valueTextEdit={this.props.valueTextEdit}
              labelTextEdit={this.props.labelTextEdit}
              alwaysEditable={this.props.alwaysEditable}
              showPrimaryFieldBtn={this.props.showPrimaryFieldBtn}
              hideDelete={this.props.hideDelete}

              dataSource={imageLabels}
              onChange={this.handleTableChange}
              activeSelectedLabel={this.state.activeSelectedLabel}
              handleItemClick={this.handleLabelTop}
              handleItemEnter={this.handleLabelClick}
              handleWrapLeave={this.handleLabelLeave}
              rerecognize={this.handleRerecognize}
            /> : <Drawer
              title="帮助信息"
              placement="right"
              closable={false}
              // onClose={onClose}
              open={true}
              getContainer={false}
              style={{
                position: 'absolute',
                width: '100%'
              }}
              contentWrapperStyle={{
                width: '100%'
              }}
            >
              <OCRReferHelper
                style={{ width: '100%', textAlign: 'left' }}
                colSpan={24}
              />
            </Drawer>
          }
        </>
      }
      {
        projectType === 'OCR_VIEW' && <>
          <EditableTable
            hideEditColumn={this.props.hideEditColumn}
            hideLableAdd={(0, true)}
            valueTextEdit={this.props.valueTextEdit}
            labelTextEdit={this.props.labelTextEdit}
            alwaysEditable={this.props.alwaysEditable}
            showPrimaryFieldBtn={this.props.showPrimaryFieldBtn}
            hideDelete={this.props.hideDelete}

            dataSource={imageLabels}
            onChange={this.handleTableChange}
            activeSelectedLabel={this.state.activeSelectedLabel}
            handleItemClick={this.handleLabelTop}
            handleItemEnter={this.handleLabelClick}
            handleWrapLeave={this.handleLabelLeave}
            rerecognize={this.handleRerecognize}
          />
        </>
      }
      {
        projectType === 'OCR_REFER_VIEW' && <>
          {
            imageLabels.length ? <EditableTableForReferField
              hideEditColumn={this.props.hideEditColumn}
              hideLableAdd={(0, true)}
              valueTextEdit={this.props.valueTextEdit}
              labelTextEdit={this.props.labelTextEdit}
              alwaysEditable={this.props.alwaysEditable}
              showPrimaryFieldBtn={this.props.showPrimaryFieldBtn}
              hideTips={true}
              hideDelete={this.props.hideDelete}

              dataSource={imageLabels}
              onChange={this.handleTableChange}
              activeSelectedLabel={this.state.activeSelectedLabel}
              handleItemClick={this.handleLabelTop}
              handleItemEnter={this.handleLabelClick}
              handleWrapLeave={this.handleLabelLeave}
              rerecognize={this.handleRerecognize}
            /> : <Drawer
              title="帮助信息"
              placement="right"
              closable={false}
              // onClose={onClose}
              open={true}
              getContainer={false}
              style={{
                position: 'absolute',
                width: '100%'
              }}
              contentWrapperStyle={{
                width: '100%'
              }}
            >
              <OCRReferHelper
                style={{ width: '100%', textAlign: 'left' }}
                colSpan={24}
              />
            </Drawer>
          }
        </>
      }
      {
        projectType === 'OCR_CHECK' && <>
          <ClickEditableTable
            hideEditColumn={this.props.hideEditColumn}
            hideLableAdd={(0, true)}
            valueTextEdit={this.props.valueTextEdit}
            labelTextEdit={this.props.labelTextEdit}
            alwaysEditable={this.props.alwaysEditable}
            showPrimaryFieldBtn={this.props.showPrimaryFieldBtn}
            hideDelete={this.props.hideDelete}

            dataSource={imageLabels}
            onChange={this.handleTableChange}
            activeSelectedLabel={this.state.activeSelectedLabel}
            handleItemClick={this.handleLabelTop}
            handleItemEnter={this.handleLabelClick}
            handleWrapLeave={this.handleLabelLeave}
            rerecognize={this.handleRerecognize}
          />
        </>
      }
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        wrapClassName='label-modal'
        // open={this.state.labelAddVisible}
        open={this.state.labelAddVisible}
        // title={!this.isEdit ? formatMessage({ id: 'select_label', defaultMessage: '请选择标签' }) : `标签 ${this.state.selectedRow.labelName} 颜色修改`}
        title={'请选择待识别字段名'}
        maskClosable={(0, false)}
        onCancel={this.addImageLabelCancel}
        onOk={this.addImageLabelOk}
        afterClose={() => {
          document.getElementById('contentWrap').style.overflow = 'auto'
          this.recalcuStyle()
        }}
      >
        <Form
          ref={this.setFormRef}
        >
          <Form.Item
            // labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}
            {...formItemLayout}
            label={'待识别字段名'}
            required
          // style={{ display: !this.props.specialLabels.includes(this.state.selectedRow.labelName) && (!this.isEdit) ? 'block' : 'none' }}
          >
            <Form.Item
              noStyle
              rules={[
                {
                  required: true,
                  message: `请选择标识项`,
                },
              ]}
              name='labelName'
            >
              <Select
                style={{
                  width: 160,
                  marginRight: -1,
                  maxHeight: 110
                }}
              >
                {
                  imageLabels.map(x => ({ name: x.key, value: x.labelName })).map(o => <Option key={o.value} value={o.value} >{o.name}</Option>)
                }
              </Select>
            </Form.Item>
            {
              (!this.props.hideEdit && imageLabels.length <= LABEL_COUNT) && <div style={{ textAlign: 'left', display: 'inline-block' }}>
                <Button
                  shape='round'
                  onClick={() => this.setState({ open: true })}
                  type="ghost"
                  style={{
                    // marginBottom: 16,
                  }}
                  icon={<PlusOutlined />}
                >
                  添加
                </Button>
              </div>
            }
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        // open={this.state.open}
        open={this.state.open}
        title="添加待识别字段名"
        okText="添加"
        cancelText="取消"
        onCancel={() => this.setState({ open: false })}
        onOk={() => {
          this.formRefLabelAdd
            .validateFields()
            .then((values) => {
              if (imageLabels.find(x => x.labelName === values.labelName)) {
                return message.error(`该字段代码 ${values.labelName} 已被使用, 请重新输入`)
              }
              this.setState({ open: false, labelAddVisible: false })
              this.formRefLabelAdd.resetFields();
              const newData = {
                labelName: values.labelName,
                key: values.key,
                description: ''
              };
              const newDataSource = [...imageLabels, newData]
              this.handleTableChange(newDataSource);
              this.form.setFieldsValue({ labelName: newData.labelName })
              this.addImageLabelOk()
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          ref={this.setFormRefLabelAdd}
          layout="vertical"
          name="form_in_modal"
          initialValues={process.env.NODE_ENV === 'development' ? {
            labelName: 'title1',
            key: 'title1'
          } : {}}
        >
          <Form.Item
            name="labelName"
            initialValue={uuidv4()}
            style={{ display: 'none' }}
            label="字段代码"
            rules={[
              {
                required: true,
                message: '请输入字段代码',
                whitespace: true
              },
            ]}
          >
            <Input maxLength={64} />
          </Form.Item>
          <Form.Item name="key" label="字段名"
            rules={[
              {
                required: true,
                message: '请输入字段名',
                whitespace: true
              },
            ]}
          >
            <Input maxLength={64} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  }
  render() {
    const { intl: { formatMessage }, projectType,
      hideLabelTable,
    } = this.props;
    let { images = [], activeIndex, imageSwitching, customContextVisible, tools, rwidth, rheight } = this.state
    let imageLabels = images[activeIndex]?.labelData?.labels ?? []
    const items = [
      (projectType !== 'OCR_CHECK' && projectType !== 'OCR_VIEW') && !this.props.hideDelete && {
        key: 'delete',
        icon: <DeleteOutlined />,
        label: '删除',
      }, {
        key: 'rerecognize',
        icon: <ScanOutlined />,
        label: '重新识别',
      }, {
        key: 'top',
        icon: <VerticalAlignTopOutlined />,
        label: '置顶',
      }, {
        key: 'bottom',
        icon: <VerticalAlignBottomOutlined />,
        label: '置底',
      }].filter(Boolean)

    return (<>
      <Spin spinning={imageSwitching}>
        <div id='label-container' ref={d => this.lc(d)} style={{ minHeight: images.length > 1 ? 725 : 820 }} >
          <ResizableBox
            width={rwidth}
            // height={rheight}
            onResize={this.onResize}
            // draggableOpts={{ enableUserSelectHack: false }}
            minConstraints={[400, 400]} maxConstraints={[this.state.style.imageContainerWitdh, images.length > 1 ? 5000 : 5000]}
            style={{ float: 'left', paddingRight: 10, }}
          >
            {/* <div style={{
              display: 'inline-block', float: 'left',
              paddingRight: 10,
              width: rwidth,
              // width: `calc(100% - ${400}px)`
            }}> */}
            <div style={{
              display: 'flex',
              flexDirection: ['top', 'bottom'].includes(this.props.toolPlacement) ? `column${this.props.toolPlacement === 'bottom' ? '-reverse' : ''}` : `row${this.props.toolPlacement === 'right' ? '-reverse' : ''}`
            }}>
              <div id='toolbar-container' style={{
                backgroundColor: '#fafafa',
                width: ['top', 'bottom'].includes(this.props.toolPlacement) ? '100%' : 80
              }}>
                <Tools
                  tools={tools}
                  activeTypes={this.state.activeTypes || []}
                  disabledTypes={this.state.disabledTypes || []}
                  onItemClick={this.onToolItemClick}
                  formatMessage={formatMessage}
                />
              </div>
              <div className='swiper-container gallery-top'
                style={images.length ? { height: images.length > 1 ? 725 : 820 } : { height: 510 }}
              >
                <div className='swiper-wrapper'>
                  <div
                    className="swiper-slide"
                    style={{ backgroundColor: '#fafafa' }}
                    ref={this.ctnWrapperRef}
                  >
                    <div id='canvas-container' style={{ backgroundColor: '#eee' }}
                      ref={this.ctnRef}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`swiper-container gallery-thumbs swiper-${projectType}`} style={{ display: images.length > 1 ? 'block' : 'none' }}>
              <div className='swiper-wrapper' />
              <div className='swiper-button-next' />
              <div className='swiper-button-prev' />
            </div>
            {
              images.length > defaultPageSize && <div style={{ paddingTop: 10, paddingBottom: 10, clear: 'both' }}>
                <Pagination
                  // simple
                  showSizeChanger={false}
                  showQuickJumper={images.length > 20}
                  pageSize={1} current={activeIndex + 1} total={images.length}
                  onChange={(page, pageSize) => {
                    this.swiper.slideTo(page - 1)
                  }}
                />
              </div>
            }
            <Spin spinning={this.state.imagesLoading} >
              {
                !images.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
              {!!images.length && this.state.imagesLoading && formatMessage({ id: 'is_loading_more' })}
            </Spin>
            {/* </div> */}
          </ResizableBox>

          {/* <RecognizeResult
              handleDeteleItem={this.handleDeleteImageLabel}
              toggleHideItem={this.handletoggleHideLabelItem}
            /> */}
          {
            !this.props.hideLabelTable && <div
              id='label-view-container'
              style={{
                width: `calc(100% - ${this.state.style.imageContainerWitdh - rwidth >= 400 ? rwidth : 0}px)`,
                float: 'left',
                maxHeight: images.length > 1 ? (projectType !== 'OCR_VIEW' ? 725 : 'auto') + (this.state.style.thumbsHeight || 0) : (projectType !== 'OCR_VIEW' ? 820 : 'auto'),
                height: imageLabels.length > 0 ? 'auto' : (images.length > 1 ? (projectType !== 'OCR_VIEW' && projectType !== 'OCR_CHECK' ? 725 : 'auto') + (this.state.style.thumbsHeight || 0) : (projectType !== 'OCR_VIEW' && projectType !== 'OCR_CHECK' ? 820 : 'auto')),
                overflowY: 'auto',
                overflowX: 'hidden',
                position: 'relative'
              }}>
              {
                this.renderLableViewByProjectType(projectType)
              }
            </div>
          }
        </div>
      </Spin>
      {customContextVisible ?
        <div ref={ref => { this.root = ref }} className="canvas-context-menu" style={{
          position: 'fixed',
          zIndex: 99
        }}>
          <Menu
            onClick={this.onContextItemClick}
            style={{
              width: 130,
              border: `1px solid #f0f0f0`
            }}
            mode="inline"
            items={items}
          />
        </div> : null}
    </>);
  }
  // renders end
}

export default ImageLabelTool
