import commonService from '@/modules/common/service'
import { getQueryStringByName } from '@/utils/utils'
import { cursorStyles } from './enum'
import axios from 'axios'

// Image直接读取302后跨域时，重新获取真正的地址
export const loadImage = (url, noMore, { commonService: _commonService } = {}) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = url
    img.onload = (event) => {
      const elem = event.target
      const meta = {
        width: elem.naturalWidth,
        height: elem.naturalHeight,
        imageData: img
      }
      resolve(meta)
    }
    if (!noMore) {
      img.onerror = async (event) => {
        const k = getQueryStringByName('object_key', url)
        const newUrl = k ? await (_commonService || commonService).getOSSTempUrl({ object_key: k }) : url
        if (newUrl) {
          loadImage(newUrl, 'noMore').then((data) => {
            resolve(data)
          }, () => {
            reject(event)
          })
        }
      }
    }
  })
}
// 图片302时 通过File读取
export async function loadImage302(url) {
  const file = await getFileBlobByUrl(url)
  const src = await getFileBase64(file)
  return loadImage(src)
}
export async function getFileBlobByUrl(url, name, { commonService: _commonService } = {}) {
  const filename = name || url.split('/').slice(-1)[0]
  const k = getQueryStringByName('object_key', url)
  const newUrl = k ? await (_commonService || commonService).getOSSTempUrl({ object_key: k }) : url
  // const blob = await fetch(url).then(res => res.blob())
  const blob = await axios.get(newUrl, {
    responseType: 'blob', baseURL: '/', unAuth: true
  })
  const ext = filename.split('?')[0].match(/\.(jpg|jpeg|gif|png|bmp|webp|tif)$/i)?.[1]
  const file = new File([blob], filename, {
    type: {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      bmp: 'image/bmp', webp: 'image/webp',
      tif: 'image/tif',
    }[ext && ext.toLowerCase()] || blob.type, size: blob.size
  })
  return file
}

export async function getFileBase64(file) {
  return new Promise((resolve, reject) => {
    if (window.FileReader) {
      let filereader = new FileReader()
      filereader.readAsDataURL(file)
      filereader.onload = () => {
        const src = filereader.result.replace(/_/g, '/').replace(/-/g, '+')
        resolve(src)
      }
      return
    }
    reject(new Error('FileReader not supported'))
  })
}

export const getBboxByEndpoints = (pts) => {
  const p0 = pts[0].xy
  const p1 = pts[parseInt(pts.length / 2)].xy
  return [Math.min(p0[0], p1[0]), Math.min(p0[1], p1[1]), Math.round(Math.abs(p0[0] - p1[0])), Math.round(Math.abs(p0[1] - p1[1]))]
}

export const getPositionByEndpoints = (pts) => {
  const p0 = pts[0].xy
  const p1 = pts[parseInt(pts.length / 2)].xy
  return [...p0, ...p1].map((x, idx) => x - (pts[0].targetAreaXY?.[idx % 2] ?? 0)).map(x => Math.round(x))
}

export const transformToBbox = (points) => {
  const p0 = points.slice(0, 2)
  const p1 = points.slice(2, 4)
  return [Math.min(p0[0], p1[0]), Math.min(p0[1], p1[1]), Math.round(Math.abs(p0[0] - p1[0])), Math.round(Math.abs(p0[1] - p1[1]))]
}

export function calcRectEndpointsByBbox(bbox) {
  return [
    {
      xy: [bbox[0], bbox[1]],
      cursor: cursorStyles.NW_RESIZE
    },
    {
      xy: [bbox[0], bbox[1] + bbox[3] / 2],
      cursor: cursorStyles.W_RESIZE
    },
    {
      xy: [bbox[0], bbox[1] + bbox[3]],
      cursor: cursorStyles.SW_RESIZE
    },
    {
      xy: [bbox[0] + bbox[2] / 2, bbox[1] + bbox[3]],
      cursor: cursorStyles.S_RESIZE
    },
    {
      xy: [bbox[0] + bbox[2], bbox[1] + bbox[3]],
      cursor: cursorStyles.SE_RESIZE
    },
    {
      xy: [bbox[0] + bbox[2], bbox[1] + bbox[3] / 2],
      cursor: cursorStyles.E_RESIZE
    },
    {
      xy: [bbox[0] + bbox[2], bbox[1]],
      cursor: cursorStyles.NE_RESIZE
    },
    {
      xy: [bbox[0] + bbox[2] / 2, bbox[1]],
      cursor: cursorStyles.N_RESIZE
    }
  ]
}
