const routes = [
    // DONE
    {
        name: 'ai_tool_box',
        componentPath: 'modules/AiLab/index',
        path: '/ai_tool_box',
        key: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__pstf',
        componentPath: 'modules/AiLab/PSTF',
        path: '/ai_tool_box/pstf',
        key: 'ai_tool_box__pstf',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__enhance',
        componentPath: 'modules/AiLab/Enhance',
        path: '/ai_tool_box/enhance',
        key: 'ai_tool_box__enhance',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__grey',
        componentPath: 'modules/AiLab/Grey',
        path: '/ai_tool_box/grey',
        key: 'ai_tool_box__grey',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__super',
        componentPath: 'modules/AiLab/Super',
        path: '/ai_tool_box/super',
        key: 'ai_tool_box__super',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__doc-rec',
        componentPath: 'modules/AiLab/DocRecgonize',
        path: '/ai_tool_box/doc_rec',
        key: 'ai_tool_box__doc-rec',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__text2image',
        componentPath: 'modules/AiLab/Text2image',
        path: '/ai_tool_box/text2image',
        key: 'ai_tool_box__text2image',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__id_photo',
        componentPath: 'modules/AiLab/IdPhoto',
        path: '/ai_tool_box/id_photo',
        key: 'ai_tool_box__id_photo',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__image_compress',
        componentPath: 'modules/AiLab/ImageCompress',
        path: '/ai_tool_box/image_compress',
        key: 'ai_tool_box__image_compress',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__dio_sum',
        componentPath: 'modules/AiLab/DioSum',
        path: '/ai_tool_box/dio_sum',
        key: 'ai_tool_box__dio_sum',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__info_extract',
        componentPath: 'modules/AiLab/InfoExtract',
        path: '/ai_tool_box/info_extract',
        key: 'ai_tool_box__info_extract',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__table-rec',
        componentPath: 'modules/AiLab/TableRecgonize',
        path: '/ai_tool_box/table_rec',
        key: 'ai_tool_box__table-rec',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__image_erase',
        componentPath: 'modules/AiLab/ImageErase',
        path: '/ai_tool_box/image_erase',
        key: 'ai_tool_box__image_erase',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__compare',
        componentPath: 'modules/AiLab/Compare',
        path: '/ai_tool_box/compare',
        key: 'ai_tool_box__compare',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__relationship',
        componentPath: 'modules/AiLab/Relationship',
        path: '/ai_tool_box/relationship',
        key: 'ai_tool_box__relationship',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__translate',
        componentPath: 'modules/AiLab/Translate',
        path: '/ai_tool_box/translate',
        key: 'ai_tool_box__translate',
        parentKey: 'ai_tool_box'
    },
    {
        name: 'ai_tool_box__wc',
        componentPath: 'modules/AiLab/WordCloud',
        path: '/ai_tool_box/wc',
        key: 'ai_tool_box__wc',
        parentKey: 'ai_tool_box'
    },
]

export default {
    routes
}
